import React, { useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemLink from "components/ListItemLink/ListItemLink";
import Tooltip from "@mui/material/Tooltip";
import LayersIcon from "@mui/icons-material/Layers";
import Divider from "@mui/material/Divider";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import PeopleIcon from "@mui/icons-material/People";

const MainListItems = ({ open }) => {
  return (
    <React.Fragment>
      <ListSubheader component="div" inset />

      <span>
        <ListItemButton>
          <Tooltip title="Legajo" placement="right">
            <span>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
            </span>
          </Tooltip>
          Legajo
        </ListItemButton>
        {open && (
          <>
            <div style={{ marginLeft: "70px" }}>
              <ListItemLink to="legajo/persona" text="Gestión de legajo" />
            </div>
          </>
        )}
      </span>
      <Divider />

      <span>
        <ListItemButton>
          <Tooltip title="Inscripciones" placement="right">
            <span>
              <ListItemIcon>
                <CalendarMonthIcon />
              </ListItemIcon>
            </span>
          </Tooltip>
          Inscripciones
        </ListItemButton>
        {open && (
          <>
            <div style={{ marginLeft: "70px" }}>
              <ListItemLink
                to="concursos/Inscripciones/Periodos"
                text="Períodos"
              />
            </div>
            <div style={{ marginLeft: "70px" }}>
              <ListItemLink
                to="concursos/Inscripciones/InscripcionGeneral"
                text="Inscripción General"
              />
            </div>
          </>
        )}
      </span>
      <Divider />

      <span>
        <ListItemButton>
          <Tooltip title="Reporte" placement="right">
            <span>
              <ListItemIcon>
                <NoteAltIcon />
              </ListItemIcon>
            </span>
          </Tooltip>
          Reporte
        </ListItemButton>
        {open && (
          <>
            <div style={{ marginLeft: "70px" }}>
              <ListItemLink to="concursos/Reporte/LOM" text="LOM" />
            </div>
          </>
        )}
      </span>
      <Divider />

      <span>
        <ListItemButton>
          <Tooltip title="Entidades" placement="right">
            <span>
              <ListItemIcon>
                <LayersIcon />
              </ListItemIcon>
            </span>
          </Tooltip>
          Entidades
        </ListItemButton>
        {open && (
          <>
            <div style={{ marginLeft: "70px" }}>
              <ListItemLink to="concursos/Entidades/EntidadesOtorgantes" text="Entidades Otorgantes" />
            </div>
            <div style={{ marginLeft: "70px" }}>
              <ListItemLink to="concursos/Entidades/Areas" text="Áreas" />
            </div>
            <div style={{ marginLeft: "70px" }}>
              <ListItemLink to="concursos/Entidades/Subareas" text="Subáreas" />
            </div>
            <div style={{ marginLeft: "70px" }}>
              <ListItemLink to="concursos/Entidades/Materias" text="Materias" />
            </div>
            <div style={{ marginLeft: "70px" }}>
              <ListItemLink to="concursos/Entidades/Titulos" text="Títulos" />
            </div>
            <div style={{ marginLeft: "70px" }}>
              <ListItemLink
                to="concursos/Entidades/Competencia"
                text="Competencias"
              />
            </div>
            <div style={{ marginLeft: "70px" }}>
              <ListItemLink
                to="concursos/Entidades/VariablesxPer"
                text="Variables"
              />
            </div>
            <div style={{ marginLeft: "70px" }}>
              <ListItemLink
                to="concursos/Entidades/VariablesParamxPer"
                text="Variables Param"
              />
            </div>
            <div style={{ marginLeft: "70px", marginBottom: "30px" }}>
              <ListItemLink
                to="concursos/Entidades/ItemsPuntuacion"
                text="Items de Puntuación"
              />
            </div>
          </>
        )}
      </span>
      {/* <Divider /> */}

      {!open ? (
        <div style={{ minHeight: "14.5vh" }} />
      ) : (
        <div style={{ minHeight: "2.6vh" }} />
      )}
    </React.Fragment>
  );
};

export default MainListItems;
