import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import {
  listarItemsDePuntuacion,
  fetchPuntajePersonaInsc_tmp,
  CrearItemDePuntuacion,
  listarDetalleItemDePuntuacion,
  deshabilitarPuntuacion,
  puntuacionProcedimiento,
  listarItemsDePuntuacionProcedimiento,
  editarItemPuntuacionProcedimiento,
  listarItemsDePuntuacionBusqueda,
  editarItemPuntuacion,
  DeshabilitarItemDePuntuacionProcedimiento,
} from './PuntuacionPersona.api';
import { request } from 'utils/helpers/request.helpers';

const API_URL = 'https://localhost:5001/api/Puntuacion_Persona';

export const fetchPuntuacionItems = createAsyncThunk(
  'puntuacion/fetchItems',
  async ({ page }, thunkAPI) => {
    try {
      const response = await listarItemsDePuntuacion(page);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getPuntajePersonaInsc_tmp = createAsyncThunk(
  'puntuacion/fetchPuntajePersonaInsc_tmp',
  async ({ pIdInscripcionPersona }) => {
    const r = await fetchPuntajePersonaInsc_tmp(pIdInscripcionPersona);
    return r;
  }
);

export const puntuacionItems_Procedimiento = createAsyncThunk(
  'puntuacion/ProcedimientoItems',
  async (id, thunkAPI) => {
    try {
      const response = await listarItemsDePuntuacionProcedimiento(id);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const DeshabilitarItemPuntuacionProcedimiento = createAsyncThunk(
  'puntuacion/deshabilitarItemProcedimiento',
  async (id, thunkAPI) => {
    try {
      const response = await DeshabilitarItemDePuntuacionProcedimiento(id);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchDetalleItemDePuntuacion = createAsyncThunk(
  'puntuacion/DetalleItemDePuntuacion',
  async (id, thunkAPI) => {
    try {
      const response = await listarDetalleItemDePuntuacion(id);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deshabilitarPuntuacionItem = createAsyncThunk(
  'puntuacion/deshabilitar',
  async (id, thunkAPI) => {
    try {
      const response = await deshabilitarPuntuacion(id);
      return { id, response };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const editarItemDePuntuacion = createAsyncThunk(
  'puntuacionPersona/editarItemDePuntuacion',
  async (item, { rejectWithValue }) => {
    try {
      const response = await editarItemPuntuacion(item);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export function editarItemDePuntuacionAPI(id, item) {
  const usuario = localStorage.getItem("token");
  const url = `${API_URL}/EditarItemDePuntuacion/${id}`;
  return request(url, {
    method: "PUT",
    body: JSON.stringify(item),
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${usuario}`
    }
  });
}

export const editarItemDePuntuacionProcedimiento = createAsyncThunk(
  'puntuacion/editarProcedimiento',
  async (id, thunkAPI) => {
    try {
      const response = await editarItemPuntuacionProcedimiento(id);
      return { id, response };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const listarItemsDePuntuacion_PBusqueda = createAsyncThunk(
  'puntuacion/buscador',
  async (page, thunkAPI) => {
    try {
      const response = await listarItemsDePuntuacionBusqueda(page);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const createPuntuacionItem = createAsyncThunk(
  'puntuacion/createItem',
  async (data, thunkAPI) => {
    try {
      const response = await CrearItemDePuntuacion(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const crearPuntuacionProcedimiento = createAsyncThunk(
  'puntuacion/createProcedimiento',
  async (data, thunkAPI) => {
    try {
      const response = await puntuacionProcedimiento(data);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const clearPuntajePersonaInsc = createAction("puntajexPersona_Insc/clearPuntajePersonaInsc");

const initialState = {
  itemsPuntuacion: [],
  puntajexPersona_Insc: [],
  itemsDetalle: [],
  itemsProcedimiento: [],
  itemsDetalleProcedimiento: [],
  status: 'idle',
  deleting: false,
  response: null,
  isLoading: false,
  error: null,
};

const errorMessage = {
  message: [
    {
      status: 500,
      description: "Se ha producido un error inesperado",
    },
  ],
};

const puntuacionSlice = createSlice({
  name: 'puntuacion',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPuntuacionItems.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPuntuacionItems.fulfilled, (state, action) => {
        state.isLoading = false;
        state.itemsPuntuacion = action.payload;
      })
      .addCase(fetchPuntuacionItems.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getPuntajePersonaInsc_tmp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPuntajePersonaInsc_tmp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.puntajexPersona_Insc = action.payload;
      })
      .addCase(getPuntajePersonaInsc_tmp.rejected, (state, action) => {
        state.isLoading = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })

      .addCase(puntuacionItems_Procedimiento.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(puntuacionItems_Procedimiento.fulfilled, (state, action) => {
        state.isLoading = false;
        state.itemsDetalleProcedimiento = action.payload;
      })
      .addCase(puntuacionItems_Procedimiento.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(fetchDetalleItemDePuntuacion.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDetalleItemDePuntuacion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.itemsDetalle = action.payload;
      })
      .addCase(fetchDetalleItemDePuntuacion.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(DeshabilitarItemPuntuacionProcedimiento.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(DeshabilitarItemPuntuacionProcedimiento.fulfilled, (state, action) => {
        state.isLoading = false;
        state.itemsDetalleProcedimiento = action.payload;
      })
      .addCase(DeshabilitarItemPuntuacionProcedimiento.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(createPuntuacionItem.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createPuntuacionItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.itemsProcedimiento = action.payload;
      })
      .addCase(createPuntuacionItem.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(crearPuntuacionProcedimiento.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(crearPuntuacionProcedimiento.fulfilled, (state, action) => {
        state.isLoading = false;
        state.itemsProcedimiento = action.payload;
      })
      .addCase(crearPuntuacionProcedimiento.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(deshabilitarPuntuacionItem.pending, (state) => {
        state.deleting = true;
        state.error = null;
      })
      .addCase(deshabilitarPuntuacionItem.fulfilled, (state, action) => {
        state.deleting = false;
        state.itemsPuntuacion = state.itemsPuntuacion.filter(item => item.idItemDePuntuacion !== action.payload.id);
      })
      .addCase(deshabilitarPuntuacionItem.rejected, (state, action) => {
        state.deleting = false;
        state.error = action.error.message;
      })

      .addCase(listarItemsDePuntuacion_PBusqueda.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(listarItemsDePuntuacion_PBusqueda.fulfilled, (state, action) => {
        state.isLoading = false;
        state.itemsPuntuacion = action.payload;
      })
      .addCase(listarItemsDePuntuacion_PBusqueda.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(editarItemDePuntuacion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editarItemDePuntuacion.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.itemsPuntuacion.findIndex((item) => item.idItemDePuntuacion === action.payload.idItemDePuntuacion);
        if (index !== -1) {
          state.itemsPuntuacion[index] = action.payload;
        }
      })
      .addCase(editarItemDePuntuacion.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      //#region clearPuntajePersonaInsc
      .addCase(clearPuntajePersonaInsc, (state) => {
        state.puntajexPersona_Insc = []; // Vacía el array puntajexPersona_Insc
      });
    //#endregion
  },
});

export const selectItemsPuntuacion = (state) => state.puntuacion.itemsPuntuacion;
export const selectPuntajexPersona_Insc = (state) => state.puntuacion.puntajexPersona_Insc;
export const selectIsLoading = (state) => state.puntuacion.isLoading;
export const selectResponsePuntPerSub = (state) => state.puntuacion.response;
export const selectItemsPuntuacionDetalle = (state) => state.puntuacion.itemsDetalle;
export const selectDeshabilitarPuntuacion = (state) => state.puntuacion.deleting;
export const selectItemsCreateProcedimiento = (state) => state.puntuacion.itemsProcedimiento;
export const selectItemsProcedimientoDetalle = (state) => state.puntuacion.itemsDetalleProcedimiento;
export const selectItemsPuntuacion_edit = (state) => state.puntuacion.itemsPuntuacion;

export default puntuacionSlice.reducer;