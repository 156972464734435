import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import { Box, Button, Autocomplete, TextField, Grid, FormControl, FormHelperText } from "@mui/material";
import { Formik, Form, Field } from "formik";

import "../PuntajexPersonaCss.css";
import {
  getInscxPersonaAll,
  selectErrorInscxPer,
  selectInscxPersonaAll
} from "store/inscripcionesxPersona/inscripcionesxPersona.slice";
import { clearPuntajePersonaInsc, getPuntajePersonaInsc_tmp } from "store/PuntuacionPersona/PuntuacionPersona.slice";


export default function PuntajexPersonaFormInscGeneral({
  persona,
  isEnable,
}) {

  const dispatch = useDispatch();
  const inscxPersonaError = useSelector(selectErrorInscxPer);
  const listaInscGeneralesxPersonaAll = useSelector(selectInscxPersonaAll);

  useEffect(() => {
    isEnable && dispatch(getInscxPersonaAll(persona?.idPersona || null));
  }, [persona])

  //Logica para enviar formulario al backend
  const handleSubmit = (values) => {
    isEnable === true && values?.idInscripcionPersona !== null &&
      dispatch(getPuntajePersonaInsc_tmp({ pIdInscripcionPersona: values?.idInscripcionPersona }));
  };

  //Validaciones del formulario.
  const validationSchema = Yup.object().shape({
    idInscripcionPersona: Yup.number().required("Este campo es obligatorio").integer(),
  });

  return (
    <Formik
      initialValues={{
        idInscripcionPersona: null,
        descIGeneralSubarea: null,
      }}
      /* Aqui se obtienen los datos que se ingresó en el formulario */
      onSubmit={handleSubmit}
    /* Configuracion de Formik para agregar las validaciones */
    //validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        submitForm,
      }) => (
        <Box
          component="form"

        >
          <Grid container spacing={2} >
            <Grid
              item
              xs={12}
              md={12}
              lg={8}
              margin={{ xs: "0px 25px 5px 40px", md: "0px 25px 5px 40px", lg: "0px 10px 5px 40px" }}
              style={{ padding: "0px" }}>
              <FormControl
                fullWidth
                error={Boolean(errors.idInscripcionPersona) && touched.idInscripcionPersona}
              >
                <Form>
                  <Field name="idInscripcionPersona">
                    {({ field }) => (
                      <Autocomplete
                        onChange={(e, value) => {
                          dispatch(clearPuntajePersonaInsc());
                          setFieldValue("idInscripcionPersona", value?.id ? value.id : null);
                          setFieldValue("descIGeneralSubarea", value?.id ? value.label : null);
                        }}
                        options={
                          (listaInscGeneralesxPersonaAll?.data &&
                            listaInscGeneralesxPersonaAll.data.map((option) => ({
                              id: option?.idInscripcionPersona,
                              label: option?.inscripcionGeneral?.descripcion + "/ SA: " + option?.subAreas?.descripcion,
                            }))
                          )
                          || []
                        }
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option.label}
                            </li>
                          );
                        }}
                        name="idInscripcionPersona"
                        value={values?.descIGeneralSubarea || null}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Inscripción General/ Subárea"
                            margin="normal"
                            variant="outlined"
                            autoComplete="off"
                            placeholder="Seleccione Inscripción General/ Subárea"
                            InputProps={{ ...params.InputProps, type: "search" }}
                            error={
                              Boolean(errors.idInscripcionPersona) && touched.idInscripcionPersona
                            }
                            sx={{
                              "input[type='search']::-webkit-search-cancel-button":
                              {
                                display: "none",
                              },
                            }}
                            helperText={
                              Boolean(errors.idInscripcionPersona) &&
                              touched.idInscripcionPersona &&
                              errors.idInscripcionPersona
                            }
                          />
                        )}
                      />
                    )}
                  </Field>
                </Form>
                {inscxPersonaError && (
                  <FormHelperText
                    style={{
                      color: "#d32f2f",
                    }}
                  >
                    Ocurrió un problema al recuperar este dato
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            {/* {console.log("values",values)} */}
            <Grid
              item
              xs={12}
              md={12}
              lg={1}
              textAlign={{ xs: 'right', md: 'right', lg: 'left' }}
              style={{ margin: "24px 27px 14px 10px", padding: "0px" }}>
              <Button
                onClick={() => { submitForm() }}
                style={{ padding: "6px 30px" }}
                disabled={!isEnable}
                variant="contained"
              >
                Buscar
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </Formik >
  );
}

