import React from "react";
import { useSelector, useDispatch } from "react-redux";

import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import SubareasFormModificar from "../SubareasForm/SubareasFormModificar";
import SubareasTable from "../SubareasTable/SubareasTable";
import SubareaNueva from "../SubareasNueva/SubareasNueva";
/* import Loader from 'components/Loader/Loader'; */
import {
  getSubareasByID,
  selectSubareasxArea,
  selectIsLoading,
} from "../../../../store/subareas/subareas.slice";
import CustomModal from "components/customModal/CustomModal";
import { CircularProgress, Tooltip } from "@mui/material";

export default function SubareasModificar({ area }) {
  // Llega con una área elegida.

  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  // Obtengo las subáreas correspondientes al área elegida.

  const handleClickOpen = () => {
    setOpen(true);
    dispatch(getSubareasByID(area.idArea));
  };

  const subareas = useSelector(selectSubareasxArea);
  const showLoader = useSelector(selectIsLoading);

  const handleClose = () => {
    setOpen(false);
  };

  /* const handleSubmit = (data) => {                            // NO SE USA handleSubmit AQUÍ!!!
    setOpen(false);
  }; */

  const muestroTabla = () => {
    if (subareas?.length !== 0) {
      return <SubareasTable area={area} subareas={subareas} />; //Llevó el dato de las subáreas que se podrán seleccionar.
    } else {
      return (
        <p
          style={{
            textAlign: "center",
          }}
        >
          Sin Subáreas asignadas.
        </p>
      );
    }
  };

  return (
    <React.Fragment>
      <Tooltip title="Modificar">
        <span>
          <IconButton
            variant="outlined"
            onClick={handleClickOpen}
            style={{ marginLeft: "10px" }}
          >
            <EditIcon />
          </IconButton>
        </span>
      </Tooltip>
      <CustomModal
        title={"Seleccione la acción que desea realizar:"}
        open={open}
        setOpen={handleClose}
        maxWidth="md"
      >
        {showLoader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <>
            <DialogContent>
              <SubareasFormModificar area={area} />
            </DialogContent>
            <DialogContent>{muestroTabla()}</DialogContent>
          </>
        )}
        <DialogContent>
          <SubareaNueva area={area} handleConteinerClose={handleClose} />
        </DialogContent>
      </CustomModal>
      {/* <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle style={{ fontWeight: "350" }}>
          Seleccione la acción que desea realizar:
        </DialogTitle>
        <DialogContent>
          <SubareasFormModificar area={area} />{" "}          
        </DialogContent>
        <DialogContent>
          <SubareaNueva area={area} />
        </DialogContent>
        <DialogContent>
          {muestroTabla()}
        </DialogContent>
      </Dialog> */}
    </React.Fragment>
  );
}
