import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editarItemDePuntuacion, fetchPuntuacionItems } from 'store/PuntuacionPersona/PuntuacionPersona.slice';
import { TextField, Button, Grid, FormControl, Stack, FormHelperText } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Formik } from "formik";

import { getNivelesEnsenanza, selectNivelesEnsenanza } from 'store/nivelesEnsenanza/nivelesEnsenanza.slice';
import CustomModal from 'components/customModal/CustomModal';
import { vsItemsPuntuacion } from '../common/YupItemsPuntuacion';

const EditItemModal = ({ isOpen, onClose, item, page, tiposItemsDePuntuacion }) => {
  
  const dispatch = useDispatch();
  const seletEnsenanza = useSelector(selectNivelesEnsenanza);
  const nivelesEnsenanza = seletEnsenanza.records;

  useEffect(() => {
    dispatch(getNivelesEnsenanza());
  }, [dispatch]);

  const handleSubmit = (data, e) => {
    const updatedItem = {
      id: item?.idItemDePuntuacion,
      ...data
    };
    dispatch(editarItemDePuntuacion(updatedItem)).then(() => {
      dispatch(fetchPuntuacionItems(page));
      onClose();
    })
  };

  return (
    <CustomModal
      title={"Editar ítem"}
      open={isOpen}
      setOpen={onClose}
      widthModal="sm"
    >
      <Formik
        initialValues={{
          codigo: item?.codigo || "",
          descripcion: item?.descripcion || "",
          unidadDeMedida: item?.unidadDeMedida || "",
          idTipoItemsDePuntuacion: item?.idTipoItemsDePuntuacion || "",
          idNivelEnsenanza: item?.idNivelEnsenanza || ""
        }}
        validationSchema={vsItemsPuntuacion}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          submitForm,
          dirty,
        }) => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} style={{ marginTop: "15px" }}>
              <FormControl fullWidth>
                <TextField
                  label="Código"
                  type="text"
                  name="codigo"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => {
                    setFieldValue("codigo", e ? e.target.value : "");
                  }}
                  autoComplete="off"
                  value={values.codigo}
                  error={Boolean(
                    errors.codigo && touched.codigo
                  )}
                  helperText={
                    Boolean(errors.codigo) &&
                    touched.codigo &&
                    errors.codigo
                  }
                  inputProps={{ maxLength: 6 }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <TextField
                  label="Descripción"
                  type="text"
                  name="descripcion"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => {
                    setFieldValue("descripcion", e ? e.target.value : "");
                  }}
                  autoComplete="off"
                  value={values.descripcion}
                  error={Boolean(
                    errors.descripcion && touched.descripcion
                  )}
                  helperText={
                    Boolean(errors.descripcion) &&
                    touched.descripcion &&
                    errors.descripcion
                  }
                  inputProps={{ maxLength: 241 }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <Autocomplete
                  onChange={(e, value) => {
                    setFieldValue("idTipoItemsDePuntuacion", value ? value?.id : "");
                  }}
                  options={
                    (tiposItemsDePuntuacion?.data &&
                      tiposItemsDePuntuacion?.data.map((option) => ({
                        id: option.idTipoItemsDePuntuacion,
                        label: option.descripcion,
                      }))) ||
                    []
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.label}
                      </li>
                    );
                  }}
                  name="idTipoItemsDePuntuacion"
                  value={
                    tiposItemsDePuntuacion?.data?.find(
                      (item) => item.idTipoItemsDePuntuacion === values.idTipoItemsDePuntuacion
                    )?.descripcion || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Tipo de ítem de Puntuación"
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                      error={touched?.idTipoItemsDePuntuacion && Boolean(errors?.idTipoItemsDePuntuacion)}
                      helperText={touched?.idTipoItemsDePuntuacion && errors?.idTipoItemsDePuntuacion}
                      sx={{
                        "input[type='search']::-webkit-search-cancel-button": { display: "none" },
                        marginTop: 0,
                        marginBottom: 0,
                      }}
                    />
                  )}
                />
                {tiposItemsDePuntuacion?.isError && (
                  <FormHelperText
                    style={{
                      color: "#d32f2f",
                    }}
                  >
                    Ocurrió un problema al recuperar este dato
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <TextField
                  label="Unidad de Medida"
                  type="text"
                  name="unidadDeMedida"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => {
                    setFieldValue("unidadDeMedida", e ? e.target.value : "");
                  }}
                  autoComplete="off"
                  value={values.unidadDeMedida}
                  error={Boolean(
                    errors.unidadDeMedida && touched.unidadDeMedida
                  )}
                  helperText={
                    Boolean(errors.unidadDeMedida) &&
                    touched.unidadDeMedida &&
                    errors.unidadDeMedida
                  }
                  inputProps={{ maxLength: 29 }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <Autocomplete
                  onChange={(e, value) => {
                    setFieldValue("idNivelEnsenanza", value ? value?.idNivelEnsenanza : "");
                  }}
                  options={nivelesEnsenanza || []}
                  getOptionLabel={(option) => option.nivelEnsenanza || ""}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.idNivelEnsenanza}>
                        {option.nivelEnsenanza}
                      </li>
                    );
                  }}
                  name="idNivelEnsenanza"
                  value={
                    nivelesEnsenanza?.find(
                      (item) => item.idNivelEnsenanza === values.idNivelEnsenanza
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Nivel de Enseñanza"
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                      error={touched?.idNivelEnsenanza && Boolean(errors?.idNivelEnsenanza)}
                      helperText={touched?.idNivelEnsenanza && errors?.idNivelEnsenanza}
                      sx={{
                        "input[type='search']::-webkit-search-cancel-button": { display: "none" },
                        marginTop: 0,
                        marginBottom: 0,
                      }}
                    />
                  )}
                />
                {nivelesEnsenanza.isError && (
                  <FormHelperText
                    style={{
                      color: "#d32f2f",
                    }}
                  >
                    Ocurrió un problema al recuperar este dato
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Stack
                spacing={2}
                direction="row"
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mt: 3,
                  mb: 2,
                }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  onClick={onClose}
                  spacing={2}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => submitForm()}
                  disabled={!dirty}
                >
                  Guardar
                </Button>
              </Stack>
            </Grid>
          </Grid>
        )}
      </Formik>
    </CustomModal>
  );
};

export default EditItemModal;
