import React from "react";
import { useDispatch } from "react-redux";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { PeriodosContext } from "../../Page/PeriodosPage";
import {
  DisabledPeriodo,
  GetSearchPeriodo,
} from "store/periodos/periodos.slice";
import { Tooltip } from "@mui/material";

export default function EliminarPeriodo({ periodo }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const pageAndSearch = React.useContext(PeriodosContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    dispatch(DisabledPeriodo({ data: periodo.idPeriodo }));
    setTimeout(() => {
      dispatch(
        GetSearchPeriodo(
          pageAndSearch ? pageAndSearch : { page: 1, data: null }
        )
      );
    }, 300);
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Tooltip title="Eliminar">
        <IconButton variant="outlined" onClick={handleClickOpen}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open}>
        <DialogTitle> Eliminar Periodo </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Estás seguro que deseas eliminar el periodo{" "}
            <b> "{periodo.descripcion}" </b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}> Cancelar </Button>
          <Button onClick={handleSubmit} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
