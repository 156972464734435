import React from "react";
import { Formik } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/es";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { vFormacionDocente } from "./YupFormacionDocente";
import { useTipoCertificacionesQuery } from "Hooks/TipoCertificaciones/UseTipoCertificacionesQuery";
import { useDetalleTipoCertificacionesQuery } from "Hooks/DetalleTipoCertificaciones/UseDetalleTipoCertificacionesQuery";

export default function FormacionDocenteForm({
  formacionDocente,
  persona,
  onSubmit,
  onClose,
}) {
  dayjs.extend(customParseFormat);
  const [tipoCertificacion, setTipoCertificacion] = React.useState({
    id: formacionDocente?.idTipoCertificacion || "",
  });
  const tiposCertificaciones = useTipoCertificacionesQuery();
  const detalleTiposCertificaciones =
    useDetalleTipoCertificacionesQuery(tipoCertificacion);

  const handleSubmit = (values) => {
    const datoAEnviar = {
      ...values,
      horas: values.horas ? +values.horas : null,
      idPersona: persona?.idPersona || null,
      idDetalleTipoCertifacion: values.idDetalleTipoCertificacion || null,
      idFormacionDocente: formacionDocente?.idFormacionDocente || null,
      deshabilitado: values.deshabilitado || false,
    };
    onSubmit(datoAEnviar);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <Formik
        initialValues={{
          idFormacionDocente: formacionDocente?.idFormacionDocente || null,
          idTipoCertificacion: formacionDocente?.idTipoCertificacion || null,
          idDetalleTipoCertificacion:
            formacionDocente?.idDetalleTipoCertifacion || null,
          descripcion: formacionDocente?.descripcion || "",
          resolucion: formacionDocente?.resolucion || "",
          horas: formacionDocente?.horas || "",
          deshabilitado: formacionDocente?.deshabilitado ? true : false,
          fechaDesde: formacionDocente?.fechaDesde
            ? dayjs(formacionDocente?.fechaDesde)
            : undefined,
          fechaHasta: formacionDocente?.fechaHasta
            ? dayjs(formacionDocente?.fechaHasta)
            : undefined,
        }}
        /* Aqui se obtienen los datos que se ingresó en el formulario */
        onSubmit={handleSubmit}
        /* Configuracion de Formik para agregar las validaciones */
        validationSchema={vFormacionDocente}
      >
        {({
          values,
          errors,
          handleChange,
          touched,
          setFieldValue,
          submitForm,
          dirty,
        }) => (
          <Box component="form" style={{ borderRadius: "5px" }} sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  onChange={(e, value) => {
                    setFieldValue("idTipoCertificacion", value?.id ?? null);
                    setTipoCertificacion({ id: value?.id });
                  }}
                  options={
                    (tiposCertificaciones?.data &&
                      tiposCertificaciones.data
                        .filter((option) => !option.deshabilitado)
                        .map((option) => ({
                          id: option.idTipoCertificacion,
                          label: option.descripcion,
                        }))) ||
                    []
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.label}
                      </li>
                    );
                  }}
                  name="idTipoCertificacion"
                  value={
                    (tiposCertificaciones?.data &&
                      tiposCertificaciones?.data?.find(
                        (item) =>
                          item.idTipoCertificacion ===
                          values.idTipoCertificacion
                      )?.descripcion) ||
                    null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Tipo de certificación"
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                        endAdornment: (
                          <React.Fragment>
                            {tiposCertificaciones?.isLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      error={
                        Boolean(errors.idTipoCertificacion) &&
                        touched.idTipoCertificacion
                      }
                      sx={{
                        "input[type='search']::-webkit-search-cancel-button": {
                          display: "none",
                        },
                        marginTop: 0, // Ajuste de margen en xs
                        marginBottom: 0,
                      }}
                      helperText={
                        Boolean(errors.idTipoCertificacion) &&
                        touched.idTipoCertificacion &&
                        errors.idTipoCertificacion
                      }
                    />
                  )}
                />
                {tiposCertificaciones.error && (
                  <FormHelperText
                    style={{
                      color: "#d32f2f",
                    }}
                  >
                    Ocurrió un problema al recuperar este dato
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  onChange={(e, value) => {
                    setFieldValue(
                      "idDetalleTipoCertificacion",
                      value?.id ?? null
                    );
                  }}
                  options={
                    (detalleTiposCertificaciones?.data &&
                      detalleTiposCertificaciones.data.map((option) => ({
                        id: option.idDetalleTipoCertificacion,
                        label: option.descripcion,
                      }))) ||
                    []
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.label}
                      </li>
                    );
                  }}
                  name="idDetalleTipoCertificacion"
                  value={
                    (detalleTiposCertificaciones?.data &&
                      detalleTiposCertificaciones.data?.find(
                        (item) =>
                          item.idDetalleTipoCertificacion ===
                          values.idDetalleTipoCertificacion
                      )?.descripcion) ||
                    null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Detalle tipo de certificación"
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                        endAdornment: (
                          <React.Fragment>
                            {detalleTiposCertificaciones?.isLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      error={
                        Boolean(errors.idDetalleTipoCertificacion) &&
                        touched.idDetalleTipoCertificacion
                      }
                      sx={{
                        "input[type='search']::-webkit-search-cancel-button": {
                          display: "none",
                        },
                        marginTop: 0, // Ajuste de margen en xs
                        marginBottom: 0,
                      }}
                      helperText={
                        Boolean(errors.idDetalleTipoCertificacion) &&
                        touched.idDetalleTipoCertificacion &&
                        errors.idDetalleTipoCertificacion
                      }
                    />
                  )}
                />
                {detalleTiposCertificaciones?.error && (
                  <FormHelperText
                    style={{
                      color: "#d32f2f",
                    }}
                  >
                    Ocurrió un problema al recuperar este dato
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={Boolean(errors.descripcion) && touched.descripcion}
                >
                  <TextField
                    id="descripcion"
                    name="descripcion"
                    label="Descripcion"
                    multiline
                    rows={4}
                    onChange={(e) =>
                      setFieldValue("descripcion", e.target.value)
                    }
                    value={values?.descripcion}
                    autoComplete="off"
                    inputProps={{ maxLength: 500 }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={8} sm={8} md={8}>
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    id="resolucion"
                    label="resolucion"
                    name="resolucion"
                    value={values?.resolucion}
                    autoComplete="off"
                    error={Boolean(errors.resolucion && touched.resolucion)}
                    onChange={(e) =>
                      setFieldValue("resolucion", e.target.value)
                    }
                    helperText={
                      touched.resolucion &&
                      Boolean(errors.resolucion) &&
                      errors.resolucion
                    }
                    inputProps={{ maxLength: 50 }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={8} sm={4} md={4}>
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    id="horas"
                    label="horas"
                    name="horas"
                    value={values?.horas}
                    autoComplete="off"
                    onChange={(e) => {
                      const onlyNumbers = e.target.value.replace(/[^\d]/g, ""); // Eliminar todo excepto los números
                      setFieldValue("horas", onlyNumbers);
                    }}
                    error={Boolean(errors.horas && touched.horas)}
                    helperText={
                      touched.horas && Boolean(errors.horas) && errors.horas
                    }
                    inputProps={{ maxLength: 6 }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={Boolean(errors.fechaDesde) && touched.fechaDesde}
                >
                  <DatePicker
                    label="Fecha de inicio"
                    name="fechaDesde"
                    value={values.fechaDesde}
                    onChange={(value) =>
                      setFieldValue("fechaDesde", value ?? "")
                    }
                    slotProps={{
                      textField: {
                        helperText: touched.fechaDesde && errors.fechaDesde,
                        error: touched.fechaDesde && Boolean(errors.fechaDesde),
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={Boolean(errors.fechaHasta) && touched.fechaHasta}
                >
                  <DatePicker
                    label="Fecha de fin"
                    name="fechaHasta"
                    value={values.fechaHasta}
                    onChange={(value) =>
                      setFieldValue("fechaHasta", value ?? "")
                    }
                    slotProps={{
                      textField: {
                        helperText: touched.fechaHasta && errors.fechaHasta,
                        error: touched.fechaHasta && Boolean(errors.fechaHasta),
                      },
                    }}
                  />
                </FormControl>
              </Grid>
              {formacionDocente?.deshabilitado ? (
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <FormControlLabel
                      control={
                        <Switch
                          name="deshabilitado"
                          checked={values.deshabilitado}
                          onChange={(e) => {                            
                            setFieldValue("deshabilitado", e.target.checked);
                          }}
                          color={values.deshabilitado ? "secondary" : "warning"}
                        />
                      }
                      label="Deshabilitado"
                    />
                  </FormControl>
                </Grid>
              ) : null}
            </Grid>
            <Stack
              spacing={2}
              direction="row"
              sx={{ display: "flex", justifyContent: "flex-end", mt: 3, mb: 2 }}
            >
              <Button
                variant="outlined"
                color="error"
                onClick={() => onClose()}
                spacing={2}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                onClick={() => submitForm()}
                disabled={!dirty}
              >
                Guardar
              </Button>
            </Stack>
          </Box>
        )}
      </Formik>
    </LocalizationProvider>
  );
}
