import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import SnackBarUtils from "utils/MUI/SnackbarUtils";
import { Toolbar, Grid } from "@mui/material";

import Loader from "components/Loader/Loader";
import InscripcionesxPersonaTable from "../PuntajexPersonaTable/PuntajexPersonaTable";
import { SnackBarMessageResponse } from "utils/Response/SnackBarMessageResponse";
import InscripcionesxPersonaFormConsulta from "../PuntajexPersonaPage/PuntajexPersonaFormConsulta";
import { useQuery } from "react-query";
import { fetchPersonaByCuil } from "store/persona/persona.api";
import { toast } from "react-toastify";
import PuntajexPersonaFormInscGeneral from "./PuntajexPersonaFormInscGeneral";
import { clearPuntajePersonaInsc, selectIsLoading, selectPuntajexPersona_Insc, selectResponsePuntPerSub } from "store/PuntuacionPersona/PuntuacionPersona.slice";

export const InscxPersonaContext = React.createContext(1);

const PuntajexPersonaPage = () => {

  const dispatch = useDispatch();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState("");
  const [persona, setPersona] = useState(null);
  const [cuil, setCuil] = useState("");
  const [bandera, setBandera] = useState(false)
  const listaInscPersonaSub = useSelector(selectPuntajexPersona_Insc);
  console.log('listaInscPersonaSub', listaInscPersonaSub)
  const isLoading = useSelector(selectIsLoading);
  const response = useSelector(selectResponsePuntPerSub);

  const isEnable = useMemo(() => cuil && /^\d{11}$/.test(cuil), [cuil]);

  const { isLoading: isFetching } = useQuery(["getPersonaByCuil", cuil], () => fetchPersonaByCuil(cuil), {
    enabled: !!isEnable,
    onSuccess: (data) => {
      setPersona(data[0]);
    },
    onError: (error) => {
      toast.error("La Persona no Existe o el cuil ingresado es incorrecto");
      setPersona(null);
    },
  });

  useEffect(() => {
    if (response) {
      console.log('response en page de puntaje', response)
      setMessageAlert(SnackBarMessageResponse(response));
      setOpenSnackbar(true);
    }
  }, [response]);

  useEffect(() => {
    console.log("pasa por fetching????")
    dispatch(clearPuntajePersonaInsc());
    setBandera(false);
  }, [cuil, isFetching]);

  const muestroTabla = () => {
    if (!(listaInscPersonaSub?.data === null || listaInscPersonaSub?.data?.length >= 0)) {
      return "";          // Concición de inicio.
    } else if (listaInscPersonaSub?.data?.length >= 0) {
      return (
        <InscripcionesxPersonaTable
          datosInscPersonaSub={listaInscPersonaSub?.data}
          persona={persona}
        />
      );
    } else if (listaInscPersonaSub?.data === null) {
      return (
        <Grid container spacing={2} >
          <Grid
            item
            xs={12}
            md={12}
            lg={8}
            margin={{ xs: "30px 25px 30px 40px", md: "30px 25px 30px 40px", lg: "30px 10px 30px 40px" }}
            style={{ textAlign: "center", color: "red", fontSize: "20px" }}
          >
            <p> Sin resultados para esta búsqueda. </p>
          </Grid>
        </Grid>
      )
    } else {
      return "";
    }
  };

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <h1 style={{ marginLeft: "12px" }}> Puntajes Agente </h1>
      <InscripcionesxPersonaFormConsulta
        persona={persona}
        setCuil={setCuil}
        isEnable={isEnable}
        isFetching={isFetching}
        setBandera={setBandera}
      />
      <div style={{ marginBottom: "30px" }} />
      {isEnable && bandera &&
        <>
          <p
            style={{
              textAlign: "center",
              background: "rgb(25,118,210,0.6)",
              borderRadius: "5px",
              padding: "10px",
              fontWeight: "bold",
            }}
          >
            {`INFORMACIÓN DE ${persona?.apellidos}, ${persona?.nombres}`}
          </p>
          <PuntajexPersonaFormInscGeneral
            persona={persona}
            isEnable={isEnable}
          />
        </>
      }
      {isEnable && muestroTabla()}
      <SnackBarUtils
        view={openSnackbar}
        message={messageAlert}
        closed={(value) => !value && setOpenSnackbar(value)}
      />
    </React.Fragment >
  );
};

export default PuntajexPersonaPage;
