import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import CustomModal from "components/customModal/CustomModal";
import { getNivelesEnsenanza } from "store/nivelesEnsenanza/nivelesEnsenanza.slice";
import TituloPersonaForm from "./TituloPersonaForm";
import { PersonaContext } from "../Forms/Common/PersonaProvider";
import {
  getTitulosxPersona,
  editTituloxPersona,
  selectResponseTitxPer,
} from "store/titulosxPersona/titulosxPersona.slice";
import { SnackBarMessageResponse } from "utils/Response/SnackBarMessageResponse";
import SnackBarUtils from "utils/MUI/SnackbarUtils";

export default function TituloPersonaEditar({ datosTitulo }) {
  const [open, setOpen] = useState(false);
  const page = 0;
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState("");
  const dispatch = useDispatch();
  const { persona } = useContext(PersonaContext);
  /* The line `const response = useSelector(selectResponseTitxPer);` is using the `useSelector` hook
  from the React-Redux library to select a specific piece of state from the Redux store. */
  const response = useSelector(selectResponseTitxPer);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (response) {
      setMessageAlert(SnackBarMessageResponse(response));
      setOpenSnackbar(true);
    }
  }, [response]);

  const handleSubmit = (data) => {
    setOpen(false);
    /* The line `dispatch(editTituloxPersona(data));` is dispatching an action to update a specific
    title associated with a person. The `editTituloxPersona` action is likely defined in the
    `titulosxPersona.slice` file and is responsible for updating the details of a title for a
    particular person. */
    dispatch(editTituloxPersona({ data }));
    dispatch(getTitulosxPersona(persona.idPersona));
    dispatch(getNivelesEnsenanza(1));
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen}>
        Editar
      </Button>
      <CustomModal open={open} setOpen={setOpen} title="Editar">
        <TituloPersonaForm
          datosTitulo={datosTitulo}
          onSubmit={handleSubmit}
          onClose={handleClose}
          persona={persona}
        />
      </CustomModal>
      <SnackBarUtils
        view={openSnackbar}
        message={messageAlert}
        closed={(value) => !value && setOpenSnackbar(value)}
      />
    </React.Fragment>
  );
}
