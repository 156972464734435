import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getInscxPersona,
  selectInscxPersona,
  selectResponseInscxPer,
} from "store/inscripcionesxPersona/inscripcionesxPersona.slice";
import SnackBarUtils from "utils/MUI/SnackbarUtils";
import { SnackBarMessageResponse } from "utils/Response/SnackBarMessageResponse";
import { InscripcionesPersonaTable } from "./InscripcionesPersonaTable";
import { Box, Pagination, Stack } from "@mui/material";
import { PersonaContext } from "../Forms/Common/PersonaProvider";
import InscripcionesNuevo from "./Modal/Nuevo/InscripcionesNuevo";
import { getNivelesEnsenanza } from "store/nivelesEnsenanza/nivelesEnsenanza.slice";

export const InscripcionesContext = React.createContext(1);
export const InscripcionesPersonaPage = () => {
  const response = useSelector(selectResponseInscxPer);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState("");
  const dispatch = useDispatch();
  const { persona } = useContext(PersonaContext);
  const [page, setPage] = React.useState(1);
  const inscripciones = useSelector(selectInscxPersona);

  const handleChange = (event, values) => {
    setPage({ ...page, page: values });
  };

  useEffect(() => {
    if (response) {
      setMessageAlert(SnackBarMessageResponse(response));
      setOpenSnackbar(true);
    }
  }, [response]);

  useEffect(() => {
    { persona && 

      dispatch(
        getInscxPersona({
          pBusqueda: undefined,
          page: page,
          pId_Persona: persona?.idPersona,
        })
      );
      dispatch(getNivelesEnsenanza());
    }
  }, [dispatch, persona, page]);

  return (
    <Box
      sx={{
        marginTop: 6,
      }}
    >
      <Box
        sx={{
          marginBottom: 2,
          marginLeft: 2,
          paddingTop: 2,
        }}
      >
        <InscripcionesNuevo persona={persona} />
      </Box>
      <InscripcionesPersonaTable inscripciones={inscripciones?.records} />
      <Stack spacing={2} style={{ margin: "20px 0px 30px 0px" }}>
        <Pagination
          count={inscripciones?.pages}
          page={page?.page}
          onChange={handleChange}
          color="primary"
        />
      </Stack>
      <SnackBarUtils
        view={openSnackbar}
        message={messageAlert}
        closed={(value) => !value && setOpenSnackbar(value)}
      />
    </Box>
  );
};
