import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Box, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PeriodoFrom from "../Common/PeriodoFrom";
import { PeriodosContext } from "../../Page/PeriodosPage";
import { GetSearchPeriodo, PutPeriodo } from "store/periodos/periodos.slice";

export default function EditarPeriodo({ periodo }) {
  const [open, setOpen] = React.useState(false);
  const pageAndSearch = React.useContext(PeriodosContext);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick" && "escapeKeyDown") return;
    setOpen(false);
  };

  const handleSubmit = (periodo) => {
    dispatch(PutPeriodo({ data: periodo }));
    setOpen(false);
    setTimeout(() => {
      dispatch(
        GetSearchPeriodo(
          pageAndSearch ? pageAndSearch : { page: 1, data: null }
        )
      );
    }, 400);
  };

  return (
    <React.Fragment>
      <Tooltip title="Modificar">
        <IconButton variant="outlined" onClick={handleClickOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>Editar Periodo</Box>
            <Box>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <PeriodoFrom
            onSubmit={handleSubmit}
            onClose={handleClose}
            data={periodo}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
