import React, { useContext } from "react";
import { useDispatch } from "react-redux";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

import { getMaterias, removeMateria } from "store/materias/materias.slice";
import { myPageMaterias } from "../MateriasPage/MateriasPage";
import { Tooltip } from "@mui/material";

export default function MateriaEliminar({ materia }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const page = useContext(myPageMaterias);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    dispatch(removeMateria(materia.idMateria));
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Tooltip title="Eliminar">
        <IconButton variant="outlined" onClick={handleClickOpen}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open}>
        <DialogTitle> Eliminar Materia </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Estás seguro que deseas eliminar la materia{" "}
            <b> "{materia.descripcion}" </b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}> Cancelar </Button>
          <Button onClick={handleSubmit} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
