import { Box, Pagination, Stack } from "@mui/material";
import Loader from "components/Loader/Loader";
import React, { useContext, useEffect } from "react";
import { FormacionDocenteTable } from "./FormacionDocenteTable";
import FormacionDocenteNuevo from "./FormacionDocenteNuevo";
import { useFormacionDocenteQuery } from "Hooks/FormacionDocente/useFormacionDocenteQuery";
import SnackBarUtils from "utils/MUI/SnackbarUtils";
import { PersonaContext } from "../Forms/Common/PersonaProvider";
import { SnackBarMessageResponse } from "utils/Response/SnackBarMessageResponse";

export const FormacionDocentePage = () => {
  const [page, setPage] = React.useState(1);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState("");
  const { persona } = useContext(PersonaContext);

  const formacionDocenteQuery = useFormacionDocenteQuery({
    page,
    idPersona: persona.idPersona,
  });

  useEffect(() => {
    if (formacionDocenteQuery.data && formacionDocenteQuery.data.message[0].status > 202) {
      setMessageAlert(SnackBarMessageResponse(formacionDocenteQuery.data));
      setOpenSnackbar(true);
    }
  }, [formacionDocenteQuery.data]);

  const handleChange = (event, values) => {
    setPage({ ...page, page: values });
  };

  return (
    <Box
      sx={{
        marginTop: 6,
      }}
    >
      {formacionDocenteQuery.isLoading && <Loader />}
      <Box
        sx={{
          marginBottom: 2,
          marginLeft: 2,
          paddingTop: 2,
        }}
      >
        <FormacionDocenteNuevo />
      </Box>
      <FormacionDocenteTable
        formaciones={formacionDocenteQuery?.data}
      />
      <Stack spacing={2} style={{ margin: "20px 0px 30px 0px" }}>
        <Pagination
          count={
            formacionDocenteQuery?.data?.pages
              ? formacionDocenteQuery.data.pages
              : 1
          }
          page={page?.page}
          onChange={handleChange}
          color="primary"
        />
      </Stack>
      <SnackBarUtils
        view={openSnackbar}
        message={messageAlert}
        closed={(value) => !value && setOpenSnackbar(value)}
      />
    </Box>
  );
};
