import * as Yup from "yup";

export const vsInscripciones = Yup.object().shape({
  idNivelEnsenanza: Yup.number()
    .required("Este campo es obligatorio")
    .integer(),
  idInscGeneral: Yup.number().required("Este campo es obligatorio").integer(),
  idDepartamentoOpcional: Yup.number().integer().nullable(),
  subareas: Yup.array().min(1, "Este campo es obligatorio"),
  domicilioInscripcion: Yup.string()
    .required("Este campo es obligatorio")
    .max(150, "Este campo no puede superar los 150 caracteres"),
  fechaInscripcion: Yup.date()
    .typeError("Ingrese una fecha válida")
    .required("Este campo es obligatorio"),
  expediente: Yup.string()
    .required("Este campo es obligatorio")
    .max(50, "Este campo no puede superar los 50 caracteres"),
  observaciones: Yup.string()
    .nullable()
    .max(250, "Este campo no puede superar los 250 caracteres"),
});
