import { useQuery } from "react-query";
import { fetchTipoCertificaciones } from "store/tipoCertificaciones/tipoCertificaciones.api";

export const useTipoCertificacionesQuery = () => {
  const tipoCertificacionesQuery = useQuery(
    {
      queryKey: ["tipoCertificacionesQuery"],
      queryFn: async () => {
        const { data } = await fetchTipoCertificaciones();
        return data;
      },
    },
    {
      refetchOnWindowFocus: false, // Desactiva el re-fetch al enfocar la ventana
      staleTime: 1000 * 60 * 60,
    }
  );

  return tipoCertificacionesQuery;
};
