import { request } from "utils/helpers/request.helpers";

const formacionDocenteUrl = `${process.env.REACT_APP_API_JU_URL_DEV}/api/formacionDocente`;

export function fetchFormacionDocente(page, idPersona) {
  return request(`${formacionDocenteUrl}/GetFormacionDocente?page=${page}&idPersona=${idPersona}`, {
    method: "GET",
  });
}

export function fetchFormacionDocenteById(id) {
  return request(
    `${formacionDocenteUrl}/GetFormacionDocenteById?idFormacionDocente=${id}`,
    {
      method: "GET",
    }
  );
}

export function createFormacionDocente(data) {
  return request(`${formacionDocenteUrl}/SaveFormacionDocente`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function updateFormacionDocente({ id, data }) {
  return request(
    `${formacionDocenteUrl}/EditFormacionDocente?idFormacionDocente=${id}`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    }
  );
}

export function deleteFormacionDocente(id) {
  return request(
    `${formacionDocenteUrl}/Deshabilitar?idFormacionDocente=${id}`,
    {
      method: "DELETE",
    }
  );
}
