import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import CustomModal from "components/customModal/CustomModal";
import { getNivelesEnsenanza } from "store/nivelesEnsenanza/nivelesEnsenanza.slice";
import TituloPersonaForm from "./TituloPersonaForm";
import { PersonaContext } from "../Forms/Common/PersonaProvider";
import {
  getTitulosxPersona,
  newTituloxPersona,
} from "store/titulosxPersona/titulosxPersona.slice";

export default function TituloPersonaNuevo() {
  const [open, setOpen] = useState(false);
  const page = 0;
  const dispatch = useDispatch();
  const { persona } = useContext(PersonaContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (data) => {
    setOpen(false);
    dispatch(newTituloxPersona(data));
    dispatch(getTitulosxPersona(persona.idPersona));
    dispatch(getNivelesEnsenanza(1));
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen}>
        Nuevo
      </Button>
      <CustomModal open={open} setOpen={setOpen} title="Nuevo título">
        <TituloPersonaForm
          onSubmit={handleSubmit}
          onClose={handleClose}
          persona={persona}
        />
      </CustomModal>
    </React.Fragment>
  );
}
