import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { formatDDMMYY } from "concursos/utils/helpers/dateFormat";
import CustomTableContainer from "components/customTable/CustomTableContainer";
import {
  tableBodyCell,
  tableBodyCenterCell,
  tableHeadCell,
  tableHeadCenterCell,
} from "components/customTable/StylesTable";
import TituloPersonaEditar from "./TituloPersonaEditar";
import { TituloPersonaEliminar } from "./TituloPersonaEliminar";

export const TituloPersonaTable = ({ titulos }) => {
  return (
    <>
      <CustomTableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            sx={{
              borderTop: "1px solid", // Agregar borde superior
            }}
          >
            <TableRow>
              <TableCell sx={{ ...tableHeadCenterCell }}>Acciones</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Título</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Carga Horaria</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Duración (Años)</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Promedio</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Fecha Egreso</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Entidad Otorgante</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Registrado</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Nº Registro </TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Fecha Registro</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Asiento</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Fecha Carga</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {titulos?.length > 0 ? (
              titulos?.map((titulo) => (
                <TableRow key={titulo.idTituloPersona}>
                  <TableCell sx={{ ...tableBodyCenterCell }}>
                    <div style={{ display: "flex", gap: "8px" }}>
                      <TituloPersonaEditar
                        datosTitulo={titulo}
                      ></TituloPersonaEditar>
                      <TituloPersonaEliminar datosTitulo={titulo} />
                    </div>
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {titulo.des_Titulo}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {titulo.cargaHoraria}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {titulo.duracionCarrera}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {titulo.promedio}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {formatDDMMYY(titulo.fechaEgreso)}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {titulo.des_EntidadOtorganteDeTitulo}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCenterCell }}>
                    {titulo.registrado === "S"
                      ? "Sí"
                      : titulo.registrado === "N"
                      ? "No"
                      : "Valor Inválido"}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {titulo.numeroRegistro ?? ""}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {titulo.fechaRegistro
                      ? formatDDMMYY(titulo.fechaRegistro)
                      : ""}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCenterCell }}>
                    {titulo.asiento === "S"
                      ? "Sí"
                      : titulo.asiento === "N"
                      ? "No"
                      : "Valor Inválido"}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {formatDDMMYY(titulo.fechaCarga)}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={15} sx={{ textAlign: "center" }}>
                  No se encontraron resultados
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CustomTableContainer>
    </>
  );
};
