import * as Yup from "yup";

export const vsItemsPuntuacion = Yup.object({
  codigo: Yup.string()
    .required("Este campo es obligatorio")
    .max(6, "Este campo debe tener como máximo 6 caracteres"),
  descripcion: Yup.string()
    .required("Este campo es obligatorio")
    .max(240, "Este campo debe tener como máximo 240 caracteres"),
  unidadDeMedida: Yup.string()
    .required("Este campo es obligatorio")
    .max(28, "Este campo debe tener como máximo 28 caracteres"),
  idTipoItemsDePuntuacion: Yup.number().required("Este campo es obligatorio"),
  idNivelEnsenanza: Yup.number().required("Este campo es obligatorio"),
});

