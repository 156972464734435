import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { formatDDMMYY } from "concursos/utils/helpers/dateFormat";
import CustomTableContainer from "components/customTable/CustomTableContainer";
import {
  tableBodyCell,
  tableBodyCenterCell,
  tableHeadCell,
  tableHeadCenterCell,
} from "components/customTable/StylesTable";
import FormacionDocenteEditar from "./FormacionDocenteEditar";
import { FormacionDocenteEliminar } from "./FormacionDocenteEliminar";

export const FormacionDocenteTable = ({ formaciones }) => {
  console.log(formaciones?.data?.records);
  return (
    <>
      <CustomTableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            sx={{
              borderTop: "1px solid", // Agregar borde superior
            }}
          >
            <TableRow>
              <TableCell sx={{ ...tableHeadCenterCell }}>Acciones</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Certificación</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Detalle</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Estado</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Descripción</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Resolución</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Horas</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Fecha Inicio</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Fecha Fin</TableCell>              
            </TableRow>
          </TableHead>

          <TableBody>
            {formaciones?.data?.records?.length > 0 ? (
              formaciones?.data?.records?.map((formacion) => (
                <TableRow key={formacion.idFormacionDocente}>
                  <TableCell sx={{ ...tableBodyCenterCell }}>
                    <div style={{ display: "flex", gap: "8px" }}>
                      <FormacionDocenteEditar
                        data={formacion}
                      ></FormacionDocenteEditar>
                      <FormacionDocenteEliminar data={formacion}/>
                    </div>
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {formacion.tipoCertificacion?.descripcion}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {formacion.detalleTipoCertificacion?.descripcion}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {formacion.estadoFormacionDocente.descripcion}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {formacion.descripcion}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {formacion.resolucion}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {formacion.horas}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {formatDDMMYY(formacion.fechaDesde)}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {formatDDMMYY(formacion.fechaHasta)}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={15} sx={{ textAlign: "center" }}>
                  No se encontraron resultados
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CustomTableContainer>
    </>
  );
};
