import { useMutation, useQueryClient } from "react-query";
import { deleteFormacionDocente } from "store/formacionDocente/formacionDocente.api";

export const useDeleteFormacionDocenteQuery = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id) => {
      const response = await deleteFormacionDocente(id);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(["FormacionDocenteQuery"]);
      },
    }
  );
};
