import * as Yup from "yup";

export const vTituloPersona = Yup.object().shape({
  idTitulo: Yup.number().required("Este campo es obligatorio"),
  duracion: Yup.number()
    .required("Este campo es obligatorio")
    .positive("El Valor debe ser mayor que cero."),
  promedio: Yup.number()
    .required("Este campo es obligatorio")
    .positive("El Valor debe ser mayor que cero."),
  fechaEgreso: Yup.date().required("Este campo es obligatorio"),
  idEntidadOtorganteDeTitulo: Yup.number().required(
    "Este campo es obligatorio"
  ),
  registrado: Yup.string().required("Este campo es obligatorio"),
  numeroRegistro: Yup.string().test(
    "requiredIfS",
    "Este campo es obligatorio",
    function (value) {
      return this.parent?.registrado !== "S" || (value && value.trim() !== "");
    }
  ),
  fechaRegistro: Yup.string().test(
    "requiredIfS",
    "Este campo es obligatorio",
    function (value) {
      return this.parent?.registrado !== "S" || (value && value.trim() !== "");
    }
  ),
  asiento: Yup.string().required("Este campo es obligatorio"),
});
