import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPuntuacionItems,
  selectItemsPuntuacion,
  selectIsLoading,
  deshabilitarPuntuacionItem,
  puntuacionItems_Procedimiento,
  listarItemsDePuntuacion_PBusqueda,
} from "store/PuntuacionPersona/PuntuacionPersona.slice";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  TextField,
  Box,
  Pagination,
} from "@mui/material";
import ProcedimientoModal from "./ProcedimientoModal";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import EditItemModal from "./EditItemModal";

const PuntuacionTable = ({page, setPage}) => {
  const dispatch = useDispatch();
  const itemsPuntuacion = useSelector(selectItemsPuntuacion);
  const paginado = itemsPuntuacion?.data;
  const isLoading = useSelector(selectIsLoading);

  const [selectedItem, setSelectedItem] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isProcedimientoModalOpen, setIsProcedimientoModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

 
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (selectedItem) {
      dispatch(puntuacionItems_Procedimiento(selectedItem.idItemDePuntuacion));
    }
  }, [dispatch, selectedItem]);

  const handleProcedimientoClick = (item) => {
    setSelectedItem(item);
    setIsProcedimientoModalOpen(true);
  };

  const handleDeleteClick = (item) => {
    setSelectedItem(item);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirmed = (id) => {
    dispatch(deshabilitarPuntuacionItem(id))
      .unwrap()
      .then(() => {
        setIsDeleteModalOpen(false);
        dispatch(fetchPuntuacionItems({ page: page.page, pageSize: rowsPerPage }));
      })
      .catch(error => {
        console.error("Error deshabilitando el item:", error);
      });
  };

  const handleEditClick = (item) => {
    setSelectedItem(item);
    setIsEditModalOpen(true);
  };

  const handlePageChange = (event, value) => {
    setPage({...page, page: value});
  };

  return (
    <TableContainer component={Paper}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography sx={{ marginTop: '15px', marginLeft: '15px' }} variant="h5">Puntuaciones</Typography>      
      </Box>
      {isLoading ? (
        <CircularProgress />
      ) : itemsPuntuacion?.data?.records?.length > 0 ? (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Código</TableCell>
                <TableCell>Descripción</TableCell>
                <TableCell>Tipo</TableCell>
                <TableCell>Unidad de Medida</TableCell>
                <TableCell>Nivel de Enseñanza</TableCell>
                <TableCell>Procedimientos</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {itemsPuntuacion?.data?.records
                .filter(item => item.deshabilitado !== 1)
                .map((item) => (
                  <TableRow key={item.idItemDePuntuacion}>
                    <TableCell>{item.codigo}</TableCell>
                    <TableCell>{item.descripcion}</TableCell>
                    <TableCell>{item.tipoItemDePuntuacion?.descripcion}</TableCell>
                    <TableCell>{item.unidadDeMedida}</TableCell>
                    <TableCell>{item.nivelEnsenanza.nivelEnsenanza}</TableCell>
                    <TableCell align="center">
                      <RemoveRedEyeIcon
                        color="secondary"
                        onClick={() => handleProcedimientoClick(item)}
                      />
                    </TableCell>
                    <TableCell>
                      <EditIcon color="disabled" onClick={() => handleEditClick(item)} />
                      <DeleteIcon
                        color="disabled"
                        onClick={() => handleDeleteClick(item)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Pagination
              count={Math.ceil(itemsPuntuacion?.data?.total_records / rowsPerPage)}
              page={page.page}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        </>
      ) : (
        <Typography>No hay datos disponibles</Typography>
      )}

      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteConfirmed}
        itemId={selectedItem?.idItemDePuntuacion}
      />

      <ProcedimientoModal
        isOpen={isProcedimientoModalOpen}
        onClose={() => setIsProcedimientoModalOpen(false)}
        selectedItem={selectedItem}
      />

      <EditItemModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        item={selectedItem}
      />
    </TableContainer>
  );
};

export default PuntuacionTable;
