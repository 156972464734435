import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid } from "@mui/material";
import InscripcionesxPersonaEditar from "../Modal/PuntajexPersonaEditar/PuntajexPersonaEditar";
import { formatDDMMYY } from "concursos/utils/helpers/dateFormat";


export default function InscripcionesxPersonaTable({ datosInscPersonaSub, persona }) {

  //console.log('datosInscPersonaSub en tabla', datosInscPersonaSub)

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <TableContainer component={Paper} style={{ marginBottom: "40px" }} >
          <Table>
            <TableHead style={{ fontWeight: "bold", textAlign: "center", background: "rgb(25,118,210,0.6)" }}>
              PUNTAJES CARGADOS
            </TableHead>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>ID</TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Inscripción/ Subárea</TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Título</TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Tipo de Título</TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Puntaje Total</TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Estado</TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Clasificado por</TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Fecha Clasificado</TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Observaciones</TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Cargado por</TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Fecha Carga</TableCell>
                  <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>Acciones</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {datosInscPersonaSub?.map(inscxPersonaSub => (
                  <TableRow
                    key={inscxPersonaSub.idPuntuacion_Persona}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell style={{ textAlign: "center" }}>{inscxPersonaSub.idPuntuacion_Persona}</TableCell>
                    <TableCell>
                      {inscxPersonaSub.inscripcion_Persona.inscripcionGeneral.descripcion}
                      / SA: {inscxPersonaSub.inscripcion_Persona.subAreas.descripcion}
                    </TableCell>
                    <TableCell>{inscxPersonaSub?.titulo?.descripcion}</TableCell>
                    <TableCell>{inscxPersonaSub?.tipo_titulo?.descripcion}</TableCell>
                    <TableCell style={{ textAlign: "right" }}>{inscxPersonaSub.puntajeTotal}</TableCell>
                    <TableCell>{
                      inscxPersonaSub.estado === "A" ? "En Análisis/ Estudio" :
                        inscxPersonaSub.estado === "F" ? "Figura en LOM" :
                          inscxPersonaSub.estado === "O" ? "Omitir en LOM" : ""
                    }
                    </TableCell>
                    <TableCell>{inscxPersonaSub?.usuario_clasificadoPor?.usuario}</TableCell>
                    <TableCell style={{ textAlign: "center" }}>{formatDDMMYY(inscxPersonaSub?.fechaClasificado)}</TableCell>
                    <TableCell>{inscxPersonaSub?.observaciones}</TableCell>
                    <TableCell>{inscxPersonaSub?.usuario_carga?.usuario}</TableCell>
                    <TableCell style={{ textAlign: "center" }}>{"Falta"}</TableCell>    {/* Falta este dato!!! */}
                    <TableCell style={{ textAlign: "center", minWidth: "120px" }} >
                      <InscripcionesxPersonaEditar inscxPersonaSub={inscxPersonaSub} persona={persona} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}