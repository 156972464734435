import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import "dayjs/locale/en-gb";
import Box from "@mui/material/Box";
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  FormControl,
  Stack,
} from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Formik } from "formik";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { vsCompetencia } from "../Common/vsCompetencia";
import { useTipoTitulosQuery } from "Hooks/TipoTitulo/UseTipoTitulosQuery";
import { useTituloQuery } from "Hooks/Titulo/UseTituloQuery";
import { useMateriaQuery } from "Hooks/Materia/UseMateriaQuery";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CompetenciaForm({ data, onSubmit, onClose }) {
  dayjs.extend(customParseFormat);
  const tipoTituloQuery = useTipoTitulosQuery();
  const titulosQuery = useTituloQuery();
  const materiasQuery = useMateriaQuery();
  const [isEdit, setIsEdit] = useState(true);

  useEffect(() => {
    setIsEdit(false);
    if (data?.idCompetencias) {
      setIsEdit(true);
    }
  }, [data]);

  const handleSubmit = (data) => {
    let competencia = {
      ...data,
      codigoCompetencia: data.codigoCompetencia.toString().padStart(5, "0"),
    };
    if (!isEdit) {
      competencia = {
        idCompetencias: 0,
        codigoCompetencia: competencia.codigoCompetencia
          .toString()
          .padStart(4, "0"),
        titulo: data.titulo,
        tipo_titulo: data.tipo_titulo,
        materias: data?.materia?.map((value) => ({
          idMateria: value.id,
          descripcion: value.label,
          idSubAreas: 0,
        })),
        area: data.area,
        subArea: data.subArea,
      };
    }
    onSubmit(competencia);
  };

  const handleClose = (event, reason) => {
    onClose();
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <Formik
        initialValues={{
          idCompetencias: data?.idCompetencias || null,
          codigoCompetencia: data?.codigoCompetencia || "",
          tipo_titulo: data?.tipo_titulo || "",
          titulo: data?.titulo || "",
          materia: isEdit ? data?.materia || "" : data?.materia || [],
          area: data?.area || "",
          subArea: data?.subArea || "",
        }}
        validationSchema={vsCompetencia(isEdit)}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          handleChange,
          touched,
          setFieldValue,
          submitForm,
          dirty,
        }) => (
          <Box component="form" sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Código"
                    type="text"
                    name="codigoCompetencia"
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      const onlyNumbers = e.target.value.replace(/[^\d]/g, ""); // Eliminar todo excepto los números
                      setFieldValue("codigoCompetencia", onlyNumbers);
                    }}
                    autoComplete="off"
                    value={values.codigoCompetencia}
                    error={Boolean(
                      errors.codigoCompetencia && touched.codigoCompetencia
                    )}
                    helperText={
                      Boolean(errors.codigoCompetencia) &&
                      touched.codigoCompetencia &&
                      errors.codigoCompetencia
                    }
                    inputProps={{ maxLength: 10 }}
                  />
                </FormControl>
              </Grid>


              

              <Grid item xs={12} sm={8}>
                <Autocomplete
                  onChange={(e, value) => {
                    setFieldValue("tipo_titulo", value?.id ?? "");
                    value?.id ?? submitForm();
                  }}
                  options={
                    (tipoTituloQuery?.data &&
                      tipoTituloQuery.data.map((option) => ({
                        id: option.idTipoTitulo,
                        label: option.descripcion,
                      }))) ||
                    []
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.label}
                      </li>
                    );
                  }}
                  name="tipo_titulo"
                  value={
                    (tipoTituloQuery?.data &&
                      tipoTituloQuery.data?.find(
                        (item) => item.idTipoTitulo === values.tipo_titulo
                      )?.descripcion) ||
                    null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Tipo de título"
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                        endAdornment: (
                          <React.Fragment>
                            {tipoTituloQuery.isLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      error={Boolean(errors.tipo_titulo) && touched.tipo_titulo}
                      sx={{
                        "input[type='search']::-webkit-search-cancel-button": {
                          display: "none",
                        },
                        marginTop: 0, // Ajuste de margen en xs
                        marginBottom: 0,
                      }}
                      helperText={
                        Boolean(errors.tipo_titulo) &&
                        touched.tipo_titulo &&
                        errors.tipo_titulo
                      }
                    />
                  )}
                />
                {tipoTituloQuery.isError && (
                  <FormHelperText
                    style={{
                      color: "#d32f2f",
                    }}
                  >
                    Ocurrió un problema al recuperar este dato
                  </FormHelperText>
                )}
              </Grid>









              <Grid item xs={12}>                
                <Autocomplete
                  onChange={(e, value) => {
                    setFieldValue("titulo", value?.id ?? "");
                  }}
                  options={
                    (titulosQuery?.data &&
                      titulosQuery.data.map((option) => ({
                        id: option.idTitulo,
                        label: option.descripcion,
                      }))) ||
                    []
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.label}
                      </li>
                    );
                  }}
                  name="titulo"
                  value={
                    (titulosQuery?.data &&
                      titulosQuery?.data.find(
                        (item) => item.idTitulo === values.titulo
                      )?.descripcion) ||
                    null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Título"
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                        endAdornment: (
                          <React.Fragment>
                            {titulosQuery.isLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      error={Boolean(errors.titulo) && touched.titulo}
                      sx={{
                        "input[type='search']::-webkit-search-cancel-button": {
                          display: "none",
                        },
                        marginTop: 0, // Ajuste de margen en xs
                        marginBottom: 0,
                      }}
                      helperText={
                        Boolean(errors.titulo) &&
                        touched.titulo &&
                        errors.titulo
                      }
                    />
                  )}
                />
                {titulosQuery.isError && (
                  <FormHelperText
                    style={{
                      color: "#d32f2f",
                    }}
                  >
                    Ocurrió un problema al recuperar este dato
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  multiple={!isEdit}
                  onChange={(e, value) => {
                    if (isEdit) {
                      setFieldValue("materia", value?.id ?? ""); // Si no es múltiple, el valor es único
                    } else {
                      // Si es múltiple, actualizamos el array de valores
                      setFieldValue("materia", value); // Actualizar el campo con el array de IDs
                    }
                  }}
                  style={{ marginTop: "-7px" }}
                  disableCloseOnSelect
                  options={materiasQuery?.data || []}
                  renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    return (
                      <li key={key} {...optionProps}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.label}
                      </li>
                    );
                  }}
                  value={
                    isEdit
                      ? materiasQuery?.data?.find(
                          (item) => item?.id === values.materia
                        ) || null
                      : materiasQuery?.data?.filter((item) =>
                          values.materia.includes(item)
                        ) || []
                  }
                  name="materia"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Materia"
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      style={{ marginTop: "0.5rem" }}
                      error={Boolean(errors.materia) && touched.materia}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {materiasQuery.isLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      helperText={
                        Boolean(errors.materia) &&
                        touched.materia &&
                        errors.materia
                      }
                    />
                  )}
                />
                {materiasQuery.isError && (
                  <FormHelperText
                    style={{
                      color: "#d32f2f",
                    }}
                  >
                    Ocurrió un problema al recuperar este dato
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
            <Stack
              spacing={2}
              direction="row"
              sx={{ display: "flex", justifyContent: "flex-end", mt: 3, mb: 2 }}
            >
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleClose()}
                spacing={2}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                onClick={() => submitForm()}
                disabled={!dirty}
              >
                Guardar
              </Button>
            </Stack>
          </Box>
        )}
      </Formik>
    </LocalizationProvider>
  );
}
