import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/es";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
  selectError,
  selectIsLoading,
  selectTitulosAll,
} from "store/titulos/titulos.slice";
import { getTitulosxPersona } from "store/titulosxPersona/titulosxPersona.slice";
import {
  selectEntidadesOtorgantesAll,
  selectError as selectErrorEntidadOtorgante,
  selectIsLoading as selectIsLoadingEntidadOtorgante,
} from "store/entidadesOtorgantes/entidadesOtorgantes.slice";
import { vTituloPersona } from "./YupTituloPersonal";

export default function TituloPersonaForm({
  datosTitulo,
  persona,
  onSubmit,
  onClose,
}) {
  const dispatch = useDispatch();
  dayjs.extend(customParseFormat);
  const [listaAllTitulos, setListaAllTitulos] = useState([]);
  const [listaEntOtorgantes, setListaEntOtorgantes] = useState([]);
  const getDesc_AllTitulos = useSelector(selectTitulosAll);
  const isTituloLoading = useSelector(selectIsLoading);
  const isTituloError = useSelector(selectError);
  const getDesc_AllEntOtorgantes = useSelector(selectEntidadesOtorgantesAll);
  const isEntidadOtorganteError = useSelector(selectErrorEntidadOtorgante);
  const isEntidadOtorganteLoading = useSelector(
    selectIsLoadingEntidadOtorgante
  );

  const siNoRegistro = [
    { registrado: 0, desRegistro: "Sí", nomenRegistro: "S" },
    { registrado: 1, desRegistro: "No", nomenRegistro: "N" },
  ];
  const siNoAsiento = [
    { asiento: 0, desAsiento: "Sí", nomenAsiento: "S" },
    { asiento: 1, desAsiento: "No", nomenAsiento: "N" },
  ];

  useEffect(() => {
    let lista1 = getDesc_AllTitulos?.map((x) => ({
      descripcion: x.descripcion + "- Código: " + x.codigo,
      idTitulo: x.idTitulo,
    }));
    setListaAllTitulos(lista1);
    let lista2 = getDesc_AllEntOtorgantes?.map((x) => ({
      descripcion: x.descripcion + "- ID: " + x.idEntidad,
      idEntidadOtorganteDeTitulo: x.idEntidad,
    }));
    setListaEntOtorgantes(lista2);
  }, [getDesc_AllEntOtorgantes, getDesc_AllTitulos]);

  const handleSubmit = (values) => {
    const datoAEnviar = {
      idPersona: persona?.idPersona || 0,
      idTituloPersona: values?.idTituloPersona || "",
      idTitulo: values?.idTitulo ?? "",
      promedio: values?.promedio || null,
      duracionCarrera: values?.duracion || "",
      fechaEgreso: values?.fechaEgreso || "",
      idEntidadOtorganteDeTitulo: values?.idEntidadOtorganteDeTitulo || "",
      registrado: values?.registrado || null,
      numeroRegistro: values?.numeroRegistro ? +values.numeroRegistro : null,
      fechaRegistro: values?.fechaRegistro || null,
      asiento: values?.asiento || "",
      cargaHoraria: values?.cargaHoraria || "",
      deshabilitado: 0,
    };
    //console.log('datoAEnviar', datoAEnviar)
    onSubmit(datoAEnviar);
    setTimeout(() => {
      dispatch(getTitulosxPersona(persona?.idPersona));
    }, 400);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
      <Formik
        initialValues={{
          idTituloPersona: datosTitulo?.idTituloPersona || "",
          idTitulo: datosTitulo?.idTitulo || "",
          duracion: datosTitulo?.duracionCarrera || "",
          promedio: datosTitulo?.promedio || "",
          fechaEgreso: datosTitulo?.fechaEgreso
            ? dayjs(datosTitulo?.fechaEgreso)
            : null,
          idEntidadOtorganteDeTitulo:
            datosTitulo?.idEntidadOtorganteDeTitulo || "",
          registrado: datosTitulo?.registrado || "N",
          numeroRegistro: datosTitulo?.numeroRegistro || "",
          fechaRegistro: datosTitulo?.fechaRegistro
            ? dayjs(datosTitulo?.fechaRegistro)
            : "",
          asiento: datosTitulo?.asiento || "",
          cargaHoraria: datosTitulo?.cargaHoraria || "",
        }}
        /* Aqui se obtienen los datos que se ingresó en el formulario */
        onSubmit={handleSubmit}
        /* Configuracion de Formik para agregar las validaciones */
        validationSchema={vTituloPersona}
      >
        {({
          values,
          errors,
          handleChange,
          touched,
          setFieldValue,
          submitForm,
          dirty,
        }) => (
          <Box component="form" style={{ borderRadius: "5px" }} sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  onChange={(e, value) => {
                    setFieldValue("idTitulo", value?.id ?? "");
                  }}
                  options={
                    (listaAllTitulos &&
                      listaAllTitulos.map((option) => ({
                        id: option.idTitulo,
                        label: option.descripcion,
                      }))) ||
                    []
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.label}
                      </li>
                    );
                  }}
                  name="idTitulo"
                  value={
                    (listaAllTitulos &&
                      listaAllTitulos?.find(
                        (item) => item.idTitulo === values.idTitulo
                      )?.descripcion) ||
                    null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Título"
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                        endAdornment: (
                          <React.Fragment>
                            {isTituloLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      error={Boolean(errors.idTitulo) && touched.idTitulo}
                      sx={{
                        "input[type='search']::-webkit-search-cancel-button": {
                          display: "none",
                        },
                        marginTop: 0, // Ajuste de margen en xs
                        marginBottom: 0,
                      }}
                      helperText={
                        Boolean(errors.idTitulo) &&
                        touched.idTitulo &&
                        errors.idTitulo
                      }
                    />
                  )}
                />
                {isTituloError && (
                  <FormHelperText
                    style={{
                      color: "#d32f2f",
                    }}
                  >
                    Ocurrió un problema al recuperar este dato
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  onChange={(e, value) => {
                    setFieldValue(
                      "idEntidadOtorganteDeTitulo",
                      value?.id ?? ""
                    );
                  }}
                  options={
                    (listaEntOtorgantes &&
                      listaEntOtorgantes.map((option) => ({
                        id: option.idEntidadOtorganteDeTitulo,
                        label: option.descripcion,
                      }))) ||
                    []
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.label}
                      </li>
                    );
                  }}
                  name="idEntidadOtorganteDeTitulo"
                  value={
                    (listaEntOtorgantes &&
                      listaEntOtorgantes?.find(
                        (item) =>
                          item.idEntidadOtorganteDeTitulo ===
                          values.idEntidadOtorganteDeTitulo
                      )?.descripcion) ||
                    null
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Entidad Otorgante"
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                        endAdornment: (
                          <React.Fragment>
                            {isEntidadOtorganteLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                      error={
                        Boolean(errors.idEntidadOtorganteDeTitulo) &&
                        touched.idEntidadOtorganteDeTitulo
                      }
                      sx={{
                        "input[type='search']::-webkit-search-cancel-button": {
                          display: "none",
                        },
                        marginTop: 0, // Ajuste de margen en xs
                        marginBottom: 0,
                      }}
                      helperText={
                        Boolean(errors.idEntidadOtorganteDeTitulo) &&
                        touched.idEntidadOtorganteDeTitulo &&
                        errors.idEntidadOtorganteDeTitulo
                      }
                    />
                  )}
                />
                {isEntidadOtorganteError && (
                  <FormHelperText
                    style={{
                      color: "#d32f2f",
                    }}
                  >
                    Ocurrió un problema al recuperar este dato
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    id="duracion"
                    label="Duración (en Años)"
                    name="duracion"
                    autoFocus
                    value={values?.duracion}
                    autoComplete="off"
                    onChange={(e) => {
                      const onlyNumbers = e.target.value.replace(/[^\d]/g, ""); // Eliminar todo excepto los números
                      setFieldValue("duracion", onlyNumbers);
                    }}
                    error={Boolean(errors.duracion && touched.duracion)}
                    helperText={
                      touched.duracion &&
                      Boolean(errors.duracion) &&
                      errors.duracion
                    }
                    inputProps={{ maxLength: 3 }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <TextField
                    type="number"
                    id="promedio"
                    label="Promedio"
                    name="promedio"
                    autoFocus
                    value={values?.promedio}
                    onChange={handleChange}
                    error={Boolean(errors.promedio && touched.promedio)}
                    helperText={
                      touched.promedio &&
                      Boolean(errors.promedio) &&
                      errors.promedio
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <DatePicker
                    label="Fecha de Egreso"
                    name="fechaEgreso"
                    value={values.fechaEgreso}
                    onChange={(value) => setFieldValue("fechaEgreso", value)}
                    slotProps={{
                      textField: {
                        helperText:
                          Boolean(errors.fechaEgreso) &&
                          touched.fechaEgreso &&
                          errors.fechaEgreso,
                        error:
                          Boolean(errors.fechaEgreso) &&
                          Boolean(touched.fechaEgreso),
                      },
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <TextField
                    type="text"
                    id="cargaHoraria"
                    label="Carga Horaria"
                    name="cargaHoraria"
                    value={values?.cargaHoraria}
                    autoComplete="off"
                    onChange={(e) => {
                      const onlyNumbers = e.target.value.replace(/[^\d]/g, ""); // Eliminar todo excepto los números
                      setFieldValue("cargaHoraria", onlyNumbers);
                    }}
                    error={Boolean(errors.cargaHoraria && touched.cargaHoraria)}
                    helperText={
                      touched.cargaHoraria &&
                      Boolean(errors.cargaHoraria) &&
                      errors.cargaHoraria
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <FormControl
                  fullWidth
                  error={!!errors.registrado && touched.registrado}
                >
                  <InputLabel id="registrado">Registrado?</InputLabel>
                  <Select
                    labelId="registrado"
                    id="registrado"
                    label="Registrado?"
                    name="registrado"
                    value={values?.registrado}
                    defaultValue={datosTitulo?.registrado || "N"}
                    onChange={(e) => {
                      if (e.target.value === "S") {
                        setFieldValue("numeroRegistro", "");
                        setFieldValue("fechaRegistro", "");
                      }
                      setFieldValue("registrado", e.target.value);
                    }}
                    error={!!errors.registrado && touched.registrado}
                  >
                    {siNoRegistro?.map((option) => (
                      <MenuItem
                        key={option.nomenRegistro}
                        value={option.nomenRegistro}
                      >
                        {option.desRegistro}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.registrado && touched.registrado && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      Este campo es obligatorio
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <TextField
                    disabled={values?.registrado === "N"}
                    type="number"
                    id="numeroRegistro"
                    label="Nº Registro"
                    name="numeroRegistro"
                    autoFocus
                    value={values?.numeroRegistro}
                    onChange={(e) => {
                      const onlyNumbers = e.target.value.replace(/[^\d]/g, ""); // Eliminar todo excepto los números
                      setFieldValue("numeroRegistro", onlyNumbers);
                    }}
                    error={Boolean(
                      errors.numeroRegistro && touched.numeroRegistro
                    )}
                    helperText={
                      touched.numeroRegistro &&
                      Boolean(errors.numeroRegistro) &&
                      errors.numeroRegistro
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <FormControl fullWidth>
                  <DatePicker
                    disabled={values?.registrado === "N"}
                    label="Fecha de Registro"
                    name="fechaRegistro"
                    value={values.fechaRegistro}
                    onChange={(value) => setFieldValue("fechaRegistro", value)}
                    slotProps={{
                      textField: {
                        helperText:
                          values?.registrado === "S" &&
                          Boolean(errors.fechaRegistro) &&
                          touched.fechaRegistro &&
                          errors.fechaRegistro,
                        error:
                          values?.registrado === "S" &&
                          Boolean(errors.fechaRegistro) &&
                          Boolean(touched.fechaRegistro),
                      },
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <FormControl
                  fullWidth
                  error={!!errors.asiento && touched.asiento}
                >
                  <InputLabel id="asiento">Asiento?</InputLabel>
                  <Select
                    labelId="asiento"
                    id="asiento"
                    label="Asiento?"
                    name="asiento"
                    value={values?.nomenAsiento}
                    defaultValue={datosTitulo?.asiento || ""}
                    onChange={handleChange}
                    error={!!errors.asiento && touched.asiento}
                  >
                    {siNoAsiento?.map((option) => (
                      <MenuItem
                        key={option.asiento}
                        value={option.nomenAsiento}
                      >
                        {option.desAsiento}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.asiento && touched.asiento && (
                    <FormHelperText
                      style={{
                        color: "#d32f2f",
                      }}
                    >
                      Este campo es obligatorio
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Stack
              spacing={2}
              direction="row"
              sx={{ display: "flex", justifyContent: "flex-end", mt: 3, mb: 2 }}
            >
              <Button
                variant="outlined"
                color="error"
                onClick={() => onClose()}
                spacing={2}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                onClick={() => submitForm()}
                disabled={!dirty}
              >
                Guardar
              </Button>
            </Stack>
          </Box>
        )}
      </Formik>
    </LocalizationProvider>
  );
}
