import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Box, Paper, Stack, Pagination, Grid } from '@mui/material'

import ItemsPuntuacionFiltro from './ItemsPuntuacionFiltro'
import {
  listarItemsDePuntuacion_PBusqueda,
  selectItemsPuntuacion,
  selectResponsePuntPerSub
} from 'store/PuntuacionPersona/PuntuacionPersona.slice'
import { selectIsLoading } from 'store/PuntuacionPersona/PuntuacionPersona.slice'
import Loader from 'components/Loader/Loader'
import { useTipoItemPuntuacionQuery } from 'Hooks/ItemsPuntuacion/useTipoItemPuntuacionQuery'
import { SnackBarMessageResponse } from 'utils/Response/SnackBarMessageResponse'
import PuntuacionTable from './tabla/PuntuacionTable'
import SnackBarUtils from 'utils/MUI/SnackbarUtils'

const initialPage = {
  page: 1,
  idTipo: null,
  codigo: "",
  desc: "",
};

export const ItemsPuntuacion = () => {
  const dispatch = useDispatch();
  let [page, setPage] = React.useState(initialPage);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState("");
  const itemsPuntuacion = useSelector(selectItemsPuntuacion);
  const isLoading = useSelector(selectIsLoading);
  const tipoItemPuntuacionQuery = useTipoItemPuntuacionQuery();
  const response = useSelector(selectResponsePuntPerSub);

  const handleSearch = ({ idTipo, codigo, desc }) => {
    setPage({ ...initialPage, idTipo, codigo, desc });
  };

  useEffect(() => {
    dispatch(listarItemsDePuntuacion_PBusqueda(page));
  }, [dispatch, page])

  const handleChange = (event, values) => {
    setPage({ ...page, page: values });
  };

  useEffect(() => {
    if (response) {
      setMessageAlert(SnackBarMessageResponse(response));
      setOpenSnackbar(true);
    }
  }, [response]);

  return (
    <React.Fragment>
      <div style={{ display: "flex", alignItems: "left" }}>
        <h1 style={{ margin: "10px 0" }}> Items de Puntuación </h1>
      </div>
      {isLoading && <Loader />}
      <Grid>
        <Paper
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            paddingBottom: 15,
            backgroundColor: "#FFFFFF",
            borderRadius: 0,
          }}
        >
          <Box sx={{ marginBottom: '20px' }} >
            <ItemsPuntuacionFiltro
              filter={1}
              onSearch={handleSearch}
              page={page}
              tipoItemPuntuacionQuery={tipoItemPuntuacionQuery}
            />
          </Box>
        </Paper >
        <PuntuacionTable page={page} setPage={setPage} tipoItemPuntuacionQuery={tipoItemPuntuacionQuery} />
        <Stack spacing={2} style={{ margin: "20px 0px 30px 0px" }}>
          <Pagination
            count={itemsPuntuacion?.data?.pages}
            page={page?.page}
            onChange={handleChange}
            color="primary"
          />
        </Stack>
        <SnackBarUtils
          view={openSnackbar}
          message={messageAlert}
          closed={(value) => !value && setOpenSnackbar(value)}
        />
      </Grid>
    </React.Fragment>
  )
}
