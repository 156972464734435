import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  DeshabilitarItemPuntuacionProcedimiento,
  crearPuntuacionProcedimiento,
  editarItemDePuntuacionProcedimiento,
  puntuacionItems_Procedimiento,
  selectItemsProcedimientoDetalle,
  selectIsLoading
} from 'store/PuntuacionPersona/PuntuacionPersona.slice';
import {
  Stack,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
  IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CustomModal from 'components/customModal/CustomModal';
import CustomTableContainer from 'components/customTable/CustomTableContainer';
import Loader from 'components/Loader/Loader';

const ProcedimientoModal = ({ isOpen, onClose, selectedItem }) => {
  const dispatch = useDispatch();
  const [procedimiento, setProcedimiento] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [editProcedimiento, setEditProcedimiento] = useState("");
  const [openNewDialog, setOpenNewDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const detallePuntosRedux = useSelector(selectItemsProcedimientoDetalle);
  const isLoading = useSelector(selectIsLoading);
  const [detallePuntos, setDetallePuntos] = useState(detallePuntosRedux);
  const [itemEdit, setItemEdit] = useState();

  useEffect(() => {
    setDetallePuntos(detallePuntosRedux || []);
  }, [detallePuntosRedux]);

  // const idItemDePuntuacion = detallePuntos.length > 0 ? detallePuntos[0].idItemDePuntuacion : null;

  const handleSaveProcedimiento = () => {
    const nuevoProcedimiento = { procedimiento, idItemDePuntuacion: selectedItem.idItemDePuntuacion };
    dispatch(crearPuntuacionProcedimiento(nuevoProcedimiento))
      .unwrap()
      .then(response => {
        setDetallePuntos([...detallePuntos, response.data]);
        setProcedimiento("");
        dispatch(puntuacionItems_Procedimiento(selectedItem.idItemDePuntuacion));
        handleCancel();
      })
      .catch(error => {
        console.error("Error creando el procedimiento:", error);
        alert("Error al guardar el procedimiento. Inténtalo de nuevo.");
      });
  };


  const handleEditClick = (index, item) => {
    setEditIndex(index);
    setEditProcedimiento(item.procedimiento);
    setItemEdit(item.idRel_ItemDePuntuacion_Procedimiento);
    setOpenEditDialog(true);
  };

  const handleEditProcedimiento = (index) => {
    const updatedProcedimiento = {
      idItemDePuntuacion: detallePuntos[index].idItemDePuntuacion,
      procedimiento: editProcedimiento,
      id: itemEdit
    };

    dispatch(editarItemDePuntuacionProcedimiento(updatedProcedimiento))
      .unwrap()
      .then(() => {
        // const newDetallePuntos = [...detallePuntos];
        // newDetallePuntos[index] = updatedProcedimiento;
        // setDetallePuntos(newDetallePuntos);
        dispatch(puntuacionItems_Procedimiento(selectedItem.idItemDePuntuacion));
        setEditIndex(null);
        setEditProcedimiento("");
        handleCancel();
      })
      .catch(error => {
        console.log("🚀 ~ handleEditProcedimiento ~ error:", error);
        alert("Error al editar el procedimiento. Inténtalo de nuevo.");
      });
  };

  const handleDeleteClick = (item) => {
    setDeleteIndex(item.idRel_ItemDePuntuacion_Procedimiento);
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = () => {

    dispatch(DeshabilitarItemPuntuacionProcedimiento(deleteIndex))
      .unwrap()
      .then(() => {
        dispatch(puntuacionItems_Procedimiento(selectedItem.idItemDePuntuacion));
        setDeleteIndex(null);
        handleCancel();
      })
      .catch(error => {
        console.error("Error deshabilitando el procedimiento:", error);
        alert("Error al borrar el procedimiento. Inténtalo de nuevo.");
      });
  };

  const handleCancel = (event, reason) => {
    if (reason && reason === "backdropClick" && "escapeKeyDown") return;
    setOpenNewDialog(false);          // Nunca puede New, Edit o Delete estar abiertos al mismo tiempo,
    setOpenEditDialog(false);         // entonces pueden cerrarse todos juntos.
    setOpenDeleteDialog(false);
  }

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick" && "escapeKeyDown") return;
    onClose();
  };

  return (
    <React.Fragment>
      {isLoading && <Loader />}
      <CustomModal
        title={"Procedimientos"}
        open={isOpen}
        setOpen={handleClose}
        widthModal="sm"
      >
        <div style={{ marginTop: "15px" }}>
          <CustomTableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ textAlign: "center" }}>Descripción</TableCell>
                  <TableCell style={{ textAlign: "right", paddingRight: "28px" }}>Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{Array.isArray(detallePuntos) && detallePuntos?.length > 0 ? (
                detallePuntos?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item?.procedimiento}</TableCell>
                    <TableCell style={{ textAlign: "right", minWidth: "120px" }}>
                      <Tooltip title="Editar">
                        <IconButton variant="outlined" >
                          <EditIcon style={{ color: "rgb(117,117,117)" }} onClick={() => handleEditClick(index, item)} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Eliminar">
                        <IconButton variant="outlined" >
                          <DeleteIcon style={{ color: "rgb(117,117,117)" }} onClick={() => handleDeleteClick(item)} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={15} sx={{ textAlign: "center" }}>
                    No se encontraron resultados
                  </TableCell>
                </TableRow>
              )
              }
              </TableBody>
            </Table>
          </CustomTableContainer>
          <Stack
            spacing={2}
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 3,
              mb: 2,
            }}
          >
            <Button
              variant="outlined"
              color="error"
              onClick={handleClose}
              spacing={2}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={() => setOpenNewDialog(true)}
            >
              Nuevo
            </Button>
          </Stack>
        </div>
      </CustomModal >

      <CustomModal
        title={"Agregar Procedimiento"}
        open={openNewDialog}
        setOpen={handleCancel}
        widthModal="sm"
      >
        <React.Fragment>
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ marginTop: "15px" }}>
              <TextField
                id="standard-basic"
                required
                fullWidth
                label="Descripción"
                value={procedimiento}
                onChange={(e) => setProcedimiento(e.target.value)}
              />
            </Grid>
          </Grid>
          <Stack              // pasarle el parametro idItemDePuntuacion al handleSaveProcedimiento.
            spacing={2}
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 3
            }}
          >
            <Button
              variant="outlined"
              color="error"
              onClick={handleCancel}
              spacing={2}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={handleSaveProcedimiento}
              disabled={!procedimiento.trim()}
            >
              Guardar
            </Button>
          </Stack>
        </React.Fragment>
      </CustomModal>

      <CustomModal
        title={"Editar Procedimiento"}
        open={openEditDialog}
        setOpen={handleCancel}
        widthModal="sm"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ marginTop: "22px" }}>
            <TextField
              id="standard-basic"
              required
              fullWidth
              label="Descripción"
              value={editProcedimiento}
              onChange={(e) => setEditProcedimiento(e.target.value)}
            />
          </Grid>
        </Grid>
        <Stack
          spacing={2}
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mt: 3
          }}
        >
          <Button
            variant="outlined"
            color="error"
            onClick={handleCancel}
            spacing={2}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={() => handleEditProcedimiento(editIndex)}
            disabled={!editProcedimiento.trim()}
          >
            Guardar
          </Button>
        </Stack>
      </CustomModal>

      <Dialog
        open={openDeleteDialog}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Eliminar Procedimiento"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Estás seguro/a que deseas eliminar este procedimiento?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment >
  );
};

export default ProcedimientoModal;
