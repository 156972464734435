import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { createPortal } from "react-dom";

export default function Loader() {
  return createPortal(
    <Backdrop
      sx={{ color: '#fff', zIndex: 9999 }}
      open
    >
      <CircularProgress color="inherit" />
    </Backdrop>,
    document.body
  );
}
