import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import GenericSearch from "concursos/utils/search/genericSearch";
import { Grid } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Loader from "components/Loader/Loader";
import { SnackBarMessageResponse } from "utils/Response/SnackBarMessageResponse";
import SnackBarUtils from "utils/MUI/SnackbarUtils";
import {
  searchEntidadesOtorgantes,
  selectEntidadesOtorgantesSearch,
  selectIsLoading,
  selectResponse,
} from "store/entidadesOtorgantes/entidadesOtorgantes.slice";
import EntidadOtorganteTable from "../Table/EntidadOtorganteTable";
import NuevaEntidadOtorgante from "../Modal/Nuevo/NuevaEntidadOtorgante";

export const EntidadOtorganteContext = React.createContext(1);

export default function EntidadOtorgantePage() {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState("");
  const [currentSearch, setcurrentSearch] = React.useState("");
  const dispatch = useDispatch();

  const isLoading = useSelector(selectIsLoading);
  const entidadesOtorgantes = useSelector(selectEntidadesOtorgantesSearch);
  const response = useSelector(selectResponse);

  let [page, setPage] = React.useState(1);

  useEffect(() => {
    dispatch(searchEntidadesOtorgantes({ page, search: currentSearch }));
  }, [page, currentSearch, dispatch]);

  const handleChange = (event, values) => {
    setPage(values);
  };

  useEffect(() => {
    if (response) {
      setMessageAlert(SnackBarMessageResponse(response));
      setOpenSnackbar(true);
    }
  }, [response]);

  const handleSearch = (e) => {
    if (e.code !== "Enter") return;
    setPage(1);
    setcurrentSearch(e.target.value);
  };

  return (
    <EntidadOtorganteContext.Provider value={{ page, search: currentSearch }}>
      <React.Fragment>
        {isLoading && <Loader />}
        <div style={{ display: "flex", alignItems: "left" }}>
          <h1 style={{ margin: "10px 0" }}>Entidades otorgantes</h1>
        </div>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <Toolbar disableGutters>
              <NuevaEntidadOtorgante />
            </Toolbar>
          </Grid>
          <Grid item xs={8}>
            <GenericSearch
              style={{ marginBottom: 0 }}
              handleSearch={handleSearch}
            />
          </Grid>
        </Grid>

        <EntidadOtorganteTable
          entidadesOtorgante={entidadesOtorgantes?.records}
        />
        <Stack spacing={2} style={{ margin: "20px 0px 30px 0px" }}>
          <Pagination
            count={entidadesOtorgantes?.pages}
            page={page}
            onChange={handleChange}
            color="primary"
          />
        </Stack>
      </React.Fragment>
      <SnackBarUtils
        view={openSnackbar}
        message={messageAlert}
        closed={(value) => !value && setOpenSnackbar(value)}
      />
    </EntidadOtorganteContext.Provider>
  );
}
