import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import "dayjs/locale/es";
import Box from "@mui/material/Box";
import { FormControl, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Formik } from "formik";
import { YupEntidadOtorgante } from "./YupEntidadOtorgante";

export default function EntidadOtorganteFrom({ data, onSubmit, onClose }) {
  
  const handleSubmit = (entidadOtorgante) => {
    onSubmit(entidadOtorgante);
  };

  const handleClose = (event, reason) => {
    onClose();
  };

  return (
    <Formik
      initialValues={{
        idEntidad: data?.idEntidad || null,
        descripcion: data?.descripcion || "",
      }}
      validationSchema={YupEntidadOtorgante}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        handleChange,
        touched,        
        submitForm,
        dirty,
      }) => (
        <Box component="form" sx={{ mt: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  id="descripcion"
                  label="Descripción"
                  name="descripcion"
                  autoFocus
                  value={values.descripcion}
                  onChange={handleChange}
                  error={Boolean(errors.descripcion) && touched.descripcion}
                  helperText={
                    Boolean(errors.descripcion) &&
                    touched.descripcion &&
                    errors.descripcion
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
          <Stack
            spacing={2}
            direction="row"
            sx={{ display: "flex", justifyContent: "flex-end", mt: 3, mb: 2 }}
          >
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleClose()}
              spacing={2}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={() => submitForm()}
              disabled={!dirty}
            >
              Guardar
            </Button>
          </Stack>
        </Box>
      )}
    </Formik>
  );
}
