import { useMutation, useQueryClient } from "react-query";
import { createFormacionDocente } from "store/formacionDocente/formacionDocente.api";

export const usePostFormacionDocenteQuery = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      const response = await createFormacionDocente(data);
      return response;
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(["FormacionDocenteQuery"]);
      },
    }
  );
};
