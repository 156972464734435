import React from "react";

import IconButton from "@mui/material/IconButton";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import DialogContent from "@mui/material/DialogContent";
import { Tooltip } from "@mui/material";
import CustomModal from "components/customModal/CustomModal";

export default function MessageModal({ titleMessage, message, title }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Tooltip title={"Ver más"}>
        <IconButton variant="outlined" onClick={handleClickOpen}>
          <LaunchOutlinedIcon />
        </IconButton>
      </Tooltip>
      <CustomModal title={titleMessage} open={open} setOpen={setOpen}>
        <DialogContent>
          <p style={{ textAlign: "center" }}>{message ?? "Sin información."}</p>
        </DialogContent>
      </CustomModal>
    </React.Fragment>
  );
}
