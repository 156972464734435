import React, { useContext } from "react";
import { useDispatch } from "react-redux";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";

import { getTitulos, removeTitulo } from "store/titulos/titulos.slice";
import { myPageTitulos } from "../TitulosPage/TituloPage";
import { Tooltip } from "@mui/material";

export default function TituloEliminar({ titulo }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const page = useContext(myPageTitulos);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    setOpen(false);
    dispatch(removeTitulo(titulo.idTitulo));
    setTimeout(() => {
      dispatch(getTitulos(page ? page : 1));
    }, 400);
  };

  return (
    <React.Fragment>
      <Tooltip title="Eliminar">
        <span>
          <IconButton variant="outlined" onClick={handleClickOpen}>
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog open={open}>
        <DialogTitle> Eliminar Título </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Estás seguro que deseas eliminar el Título{" "}
            <b> "{titulo.descripcion}" </b>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}> Cancelar </Button>
          <Button onClick={handleSubmit} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
