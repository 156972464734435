import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import MateriaForm from "../MateriaForm/MateriaForm";
import { updateMateriaById } from "store/materias/materias.slice";
import { fetchMateriasEdit } from "store/materias/materias.api";
import ConfirmDialog from "concursos/views/Persona/Forms/Common/ConfirmDialog";
import Loader from "components/Loader/Loader";
import CustomModal from "components/customModal/CustomModal";
import { Tooltip } from "@mui/material";

export default function MateriaEditar({ materia }) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [isLoadding, setIsLoadding] = React.useState(false);
  const [data, setData] = React.useState({});

  const handleClickOpen = async () => {
    await fetchMateria();
  };

  const fetchMateria = async () => {
    try {
      setIsLoadding(true);
      const { data } = await await fetchMateriasEdit(materia.idMateria ?? 0);
      const areas =
        data.areasPre.map((option) => ({
          id: option.idSubAreas,
          label: `${option.idSubAreas} - ${option.descripcion}`,
        })) || [];

      const niveles =
        data.nivelEnsenanzaPre.map((option) => ({
          id: option.idNivelEnsenanza,
          label: option.nivelEnsenanza,
        })) || [];

      setData({ ...materia, idsRelSubAreas: areas, idsRelNivel: niveles });
      setOpen(true);
    } catch (error) {
      setOpenDialog(true);
    } finally {
      setIsLoadding(false);
    }
  };

  const onCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditMateria = (data) => {
    dispatch(updateMateriaById(data));
    setOpen(false);
  };

  return (
    <>
      <ConfirmDialog
        mensaje={
          "Se ha producido un error al recuperar informacion de la materia."
        }
        onClose={onCloseDialog}
        open={openDialog}
        title={"Atención"}
      />
      {isLoadding ? (
        <Loader />
      ) : (
        <>
          <Tooltip title="Modificar">
            <IconButton variant="outlined" onClick={handleClickOpen}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <CustomModal
            title={"Editar Materia"}
            open={open}
            setOpen={handleClose}
            maxWidth="sm"
          >
            <DialogContent>
              <MateriaForm
                data={data}
                onSubmit={handleEditMateria}
                onClose={handleClose}
              />
            </DialogContent>
          </CustomModal>
        </>
      )}
    </>
  );
}
