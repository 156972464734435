import { request } from "utils/helpers/request.helpers";

const PuntuacionItens = `${process.env.REACT_APP_API_URL}/api/Puntuacion_Persona`;
  
export function listarItemsDePuntuacion(page) {
  const usuario = localStorage.getItem("token");  
  const url = `${PuntuacionItens}/ListarItemsDePuntuacion?usuario=${usuario}&page=${page}`;
  return request(url, { method: "GET" });
}

export function fetchPuntajePersonaInsc_tmp(pIdInscripcionPersona) { 
  const url = `${PuntuacionItens}/ListarPuntuacion_Persona_tmp?pIdInscripcionPersona=${pIdInscripcionPersona}`;
  return request(url, { method: "GET" });
}

export function listarItemsDePuntuacionBusqueda(page) {
  const usuario = localStorage.getItem("token");
  let parameters = '';
  if (page.codigo) {
    parameters += `&codigo=${page.codigo}`;
  }
  if(page.desc) {
    parameters += `&desc=${page.desc}`;
  }
  if (page.idTipo) {
    parameters += `&tipo=${page.idTipo}`;
  }
  const url = `${PuntuacionItens}/ListarItemsDePuntuacion?page=${page.page}&usuario=${usuario}${parameters}`;
  return request(url, { method: "GET" });
}

export function ListarTipoItemDePuntuacionApi() {  
  const url = `${PuntuacionItens}/ListarTipoItemDePuntuacion`;
  return request(url, { method: "GET" });
}

export function listarItemsDePuntuacionProcedimiento(id) {
  const url = `${PuntuacionItens}/ListItemDePuntuacion_ProcedimientoByIdItemDePuntuacion/${id}`;
  return request(url, { method: "GET" });
}

export function listarDetalleItemDePuntuacion(id) {
  const url = `${PuntuacionItens}/DetalleItemDePuntuacion/${id}`;
  return request(url, { method: "GET" });
}

export function deshabilitarPuntuacion(id) {
  const url = `${PuntuacionItens}/DeshabilitarItemDePuntuacion/${id}`;
  return request(url, { method: "PUT" });
}

export function DeshabilitarItemDePuntuacionProcedimiento(id) {
  const url = `${PuntuacionItens}/DeshabilitarItemDePuntuacion_Procedimiento/${id}`;
  return request(url, { method: "PUT" });
}

export function editarItemPuntuacion(item) {
  const url = `${PuntuacionItens}/EditarItemDePuntuacion/${item.id}`;
  return request(url, {
    method: "PUT",
    body: JSON.stringify(item),
    headers: {
      "Content-Type": "application/json",
    }
  });
}


export function editarItemPuntuacionProcedimiento({ idItemDePuntuacion, procedimiento, id }) {
  const url = `${PuntuacionItens}/EditarItemDePuntuacion_Procedimiento/${id}`;
  const body = { idItemDePuntuacion, procedimiento };
  return request(url, {
    method: "PUT",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  });
}



export function puntuacionProcedimiento(data) {
  const url = `${PuntuacionItens}/CrearItemDePuntuacion_Procedimiento`;
  return request(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  }); 
}

export function CrearItemDePuntuacion(data) {
  const url = `${PuntuacionItens}/CrearItemDePuntuacion`;
  return request(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });
}
