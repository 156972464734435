import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { formatDDMMYY } from "concursos/utils/helpers/dateFormat";
import CustomTableContainer from "components/customTable/CustomTableContainer";
import {
  tableBodyCell,
  tableHeadCell,
  tableHeadCenterCell,
} from "components/customTable/StylesTable";
import { useSelector } from "react-redux";
import { selectIsLoadingInscxPer } from "store/inscripcionesxPersona/inscripcionesxPersona.slice";
import Loader from "components/Loader/Loader";

export const InscripcionesPersonaTable = ({ inscripciones }) => {
  const isLoading = useSelector(selectIsLoadingInscxPer);

  return (
    <>
      {isLoading && <Loader />}
      <CustomTableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            sx={{
              borderTop: "1px solid", // Agregar borde superior
            }}
          >
            <TableRow>
              <TableCell sx={{ ...tableHeadCenterCell }}>Acciones</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>
                Inscripción General
              </TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Subárea</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Dpto Opcional</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>
                Domicilio Inscripción
              </TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Fecha Inscripción</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Expediente</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Observaciones</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Fecha Carga</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {inscripciones?.length > 0 ? (
              inscripciones?.map((inscripcion) => (
                <TableRow key={inscripcion.idInscripcionPersona}>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {inscripcion.inscripcionGeneral.descripcion}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {inscripcion.subAreas.descripcion}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {inscripcion.inscripcionGeneral.nivelEnsenanza.nivel ===
                      "P" &&
                      (inscripcion.departamentoOpcional
                        ? inscripcion.departamentoOpcional.nombre
                        : "(Sin D.O.)")}
                    {inscripcion.inscripcionGeneral.nivelEnsenanza.nivel ===
                      "M" && ""}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {inscripcion.domicilioInscripcion}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {formatDDMMYY(inscripcion.fechaInscripcion)}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {inscripcion.expediente
                      ? inscripcion.expediente
                      : "(Sin Exp.)"}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {inscripcion.observaciones}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {formatDDMMYY(inscripcion.fechaCarga)}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={15} sx={{ textAlign: "center" }}>
                  No se encontraron resultados
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CustomTableContainer>
    </>
  );
};
