import { useQuery } from "react-query";
import { fetchDetalleTipoCertificaciones } from "store/detalleTipoCertificaciones/detalleTipoCertificaciones.api";

export const useDetalleTipoCertificacionesQuery = ({id}) => {
  const detalleTipoCertificacionesQuery = useQuery(
    {
      queryKey: ["detalleTipoCertificacionesQuery", {id}],
      queryFn: async () => {
        console.log("id", id);
        const { data } = await fetchDetalleTipoCertificaciones(id);
        return data;
      },
      enabled: !!id,
    },
    {
      refetchOnWindowFocus: false, // Desactiva el re-fetch al enfocar la ventana
      staleTime: 1000 * 60 * 60,
    }
  );

  return detalleTipoCertificacionesQuery;
};