import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import {
  getTitulosxPersona,
  removeTituloxPersona,
} from "store/titulosxPersona/titulosxPersona.slice";
import DeleteIcon from "@mui/icons-material/Delete";

export const TituloPersonaEliminar = ({ datosTitulo }) => {
  //console.log('datosTitulo en titxper eliminar', datosTitulo)
  console.log("datosTitulo eliminar", datosTitulo);

  const [open, setOpen] = React.useState(false);
  /*   const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState(""); */
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    dispatch(removeTituloxPersona(datosTitulo.idTituloPersona));
    setTimeout(() => {
      dispatch(getTitulosxPersona(datosTitulo.idPersona));
    }, 400);
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen}>
        Eliminar
      </Button>
      <Dialog open={open}>
        <DialogTitle> Eliminar asignación </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Estás seguro/a que deseas eliminar la asignación del Título{" "}
            <b> "{datosTitulo.des_Titulo}" </b> a {datosTitulo.apellidoyNombre}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleSubmit} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
