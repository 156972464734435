import { Route, Routes, useLocation } from "react-router-dom";
import { MenuForm } from "./Menu/MenuForm";
import { DetallePersona } from "./Detalle/DetallePersona";
import PersonaPage from "../Page/PersonaPage";
import { PuntajePersona } from "../Puntaje/PuntajePersona";
import { CertificadosPersonaPage } from "../Certificados/CertificadosPersonaPage";
import { TituloPersonaPage } from "../Titulo/TituloPersonaPage";
import { InscripcionesPersonaPage } from "../Inscripciones/InscripcionesPersonaPage";

export const PersonaMain = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  const isPageMain =
    currentPath === "/legajo/persona/*" ||
    currentPath === "/legajo/persona/" ||
    currentPath === "/legajo/persona";

  return (
    <div>
      {!isPageMain && <MenuForm />}
      <main>
        <Routes>
          <Route path="detalle" element={<DetallePersona />} />
          <Route path="titulos" element={<TituloPersonaPage />} />
          <Route path="puntaje" element={<PuntajePersona />} />
          <Route path="inscripciones" element={<InscripcionesPersonaPage />} />
          <Route path="listado" element={<PersonaPage />} />
          <Route path="certificados" element={<CertificadosPersonaPage />} />
          <Route path="*" element={<PersonaPage />} />
        </Routes>
      </main>
    </div>
  );
};
