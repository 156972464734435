import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";

import {
  fetchGradoDeTituloAll,
} from "./gradoDeTitulo.api";

const initialState = {
  entities: [],
  response: null,
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  error: null,
};

const errorMessage = {
  message: [
    {
      status: 500,
      description: "Se ha producido un error inesperado",
    },
  ],
};

export const getGradoDeTituloAll = createAsyncThunk(
  "gradoDeTitulo/fetchGradoDeTituloAll",
  async () => {
    const r = await fetchGradoDeTituloAll();
    return r;
  }
);

export const GradoDeTituloSlice = createSlice({
  name: "gradoDeTitulo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getGradoDeTituloAll.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getGradoDeTituloAll.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload;
      })
      .addCase(getGradoDeTituloAll.rejected, (state, action) => {
        state.loading = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })
  },
});

export const selectGradoDeTituloAll = (state) => state.gradoDeTitulo.entities; 
export const selectResponseGradoDeTitulo = (state) => state.gradoDeTitulo.response;
export const selectIsLoadingGradoDeTitulo = (state) => state.gradoDeTitulo.loading;
export const selectIsCreatingGradoDeTitulo = (state) => state.gradoDeTitulo.creating;
export const selectIsEditingGradoDeTitulo = (state) => state.gradoDeTitulo.editing;
export const selectIsDeletingGradoDeTitulo = (state) => state.gradoDeTitulo.deleting;
export const selectErrorGradoDeTitulo = (state) => state.gradoDeTitulo.error;

export default GradoDeTituloSlice.reducer;
