import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDeleteFormacionDocenteQuery } from "Hooks/FormacionDocente/UseDeleteFormacionDocenteQuery";
import React from "react";
import { SnackBarMessageResponse } from "utils/Response/SnackBarMessageResponse";

export const FormacionDocenteEliminar = ({ data }) => {  
  const [ setOpenSnackbar] = React.useState(false);
  const [ setMessageAlert] = React.useState("");
  const deleteFormacionDocente = useDeleteFormacionDocenteQuery();
  const [open, setOpen] = React.useState(false);
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    deleteFormacionDocente.mutate(data.idFormacionDocente, {
      onSuccess: () => {
        handleClose();
        setMessage([
          {              
            message: "200: Formación docente deshabilitada correctamente",
          },
        ]);
        setOpen(false);
      },
      onError: (error) => {
        setMessage([
          {            
            message:
              `${error.response.status}: Se ha producido un error al deshabilitar la formación docente`,
          },
        ]);
      },
    });
    setOpen(false);
  };

  const setMessage = (message) => {
    setOpenSnackbar(true);
    setMessageAlert(SnackBarMessageResponse(message));
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen} disabled={data.deshabilitado}>
        Eliminar
      </Button>
      <Dialog open={open}>
        <DialogTitle> Eliminar asignación </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Estás seguro/a que deseas deshabilitar la Formación Docente{" "}
            <b>
              {" "}
              "{data?.tipoCertificacion?.descripcion} -{" "}
              {data?.detalleTipoCertificacion?.descripcion}{" "}
              "
            </b>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleSubmit} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
