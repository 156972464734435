import React, { useContext } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { formatDDMMYY } from "concursos/utils/helpers/dateFormat";
import EliminarPersona from "../Forms/Eliminar/EliminarPersona";
import { Button } from "@mui/material";
import { PersonaContext } from "../Forms/Common/PersonaProvider";
import {
  tableBodyCell,
  tableHeadCell,
  tableHeadCenterCell,
} from "components/customTable/StylesTable";
import { useNavigate } from "react-router-dom";

const PersonaTable = ({ personas, onSelect }) => {
  const { setPersona } = useContext(PersonaContext);
  const navigate = useNavigate();
  const handleModificarPersona = (persona) => {
    setPersona(persona);
    onSelect(true);
  };

  const handleViewClick = (persona) => {
    setPersona(persona);
    // Navegar a /pageA/b con el ID de la persona
    navigate(`detalle`);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}>
      <TableContainer sx={{ maxHeight: 450 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            sx={{
              borderTop: "1px solid", // Agregar borde superior
            }}
          >
            <TableRow>
              <TableCell sx={{ ...tableHeadCenterCell }}>Acciones</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>ID</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Apellido y nombre</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Sexo</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Tipo de documento</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Documento</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>CUIL</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>
                Fecha de nacimiento
              </TableCell>
              <TableCell sx={{ ...tableHeadCell }}>
                Lugar de nacimiento
              </TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Estado civil</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Domicilio</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Localidad</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Departamento</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Antecedentes</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Telefono</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {personas?.length > 0 ? (
              personas?.map((persona) => (
                <TableRow key={persona.idPersona}>
                  <TableCell sx={{ ...tableBodyCell }}>
                    <div style={{ display: "flex", gap: "8px" }}>
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => handleViewClick(persona)}
                      >
                        Ver
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => handleModificarPersona(persona)}
                      >
                        Modificar
                      </Button>
                      <EliminarPersona persona={persona} />
                    </div>
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {persona.idPersona}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {persona.nombres} {persona.apellidos}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {persona?.sexos.descripcion}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {persona?.tipo_documento.descripcion}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {persona.documento}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {persona.cuit}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {formatDDMMYY(persona.fechaNacimiento)}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {persona.lugarNacimiento}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {persona?.estadoCiviles?.estadoCivil}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {persona.domicilio}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {persona?.departamentos?.nombre}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {persona?.localidadResidencia?.localidad}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {persona?.tipoAntecedente?.descripcion}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {persona.telefono}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {persona.email}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={15} sx={{ textAlign: "center" }}>
                  No se encontraron resultados
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default PersonaTable;
