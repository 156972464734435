import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPuntuacionItems,
  selectItemsPuntuacion,
  deshabilitarPuntuacionItem,
  puntuacionItems_Procedimiento,
} from "store/PuntuacionPersona/PuntuacionPersona.slice";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import ProcedimientoModal from "./ProcedimientoModal";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import EditItemModal from "./EditItemModal";

const PuntuacionTable = ({ page, setPage, tipoItemPuntuacionQuery }) => {
  const dispatch = useDispatch();
  const itemsPuntuacion = useSelector(selectItemsPuntuacion);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isProcedimientoModalOpen, setIsProcedimientoModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);


  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (selectedItem) {
      dispatch(puntuacionItems_Procedimiento(selectedItem.idItemDePuntuacion));
    }
  }, [dispatch, selectedItem]);

  const handleProcedimientoClick = (item) => {
    setSelectedItem(item);
    setIsProcedimientoModalOpen(true);
  };

  const handleDeleteClick = (item) => {
    setSelectedItem(item);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirmed = (id) => {
    dispatch(deshabilitarPuntuacionItem(id)).then(() => {
        dispatch(fetchPuntuacionItems({ page: page.page, pageSize: rowsPerPage }));
        setIsDeleteModalOpen(false);
      })
  };

  const handleEditClick = (item) => {
    setSelectedItem(item);
    setIsEditModalOpen(true);
  };

  const tableHeadCell = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontWeight: 600,
    borderTop: "1px solid rgba(224, 224, 224, 1)"
  };

  return (
    <TableContainer component={Paper}>
      {itemsPuntuacion?.data?.records?.length > 0 ? (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ ...tableHeadCell, textAlign: "center" }}>Código</TableCell>
                <TableCell style={{ ...tableHeadCell }}>Descripción</TableCell>
                <TableCell style={{ ...tableHeadCell }}>Tipo de ítem</TableCell>
                <TableCell style={{ ...tableHeadCell }}>Unidad de Medida</TableCell>
                <TableCell style={{ ...tableHeadCell }}>Nivel de Enseñanza</TableCell>
                <TableCell style={{ ...tableHeadCell }}>Procedimientos</TableCell>
                <TableCell style={{ ...tableHeadCell, textAlign: "center" }}>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {itemsPuntuacion?.data?.records.map((item) => (
                <TableRow key={item.idItemDePuntuacion}>
                  <TableCell>{item.codigo}</TableCell>
                  <TableCell>{item.descripcion}</TableCell>
                  <TableCell>{item.tipoItemDePuntuacion?.descripcion}</TableCell>
                  <TableCell>{item.unidadDeMedida}</TableCell>
                  <TableCell>{item.nivelEnsenanza.nivelEnsenanza}</TableCell>
                  <TableCell style={{ textAlign: "center" }} >
                    <Tooltip title="Procedimientos">
                        <IconButton variant="outlined" >
                          <RemoveRedEyeIcon style={{ color: "rgb(117,117,117)" }} onClick={() => handleProcedimientoClick(item)} />
                        </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell style={{ minWidth: "120px", textAlign: "center" }}>
                    <Tooltip title="Editar">
                        <IconButton variant="outlined" >
                          <EditIcon style={{ color: "rgb(117,117,117)" }} onClick={() => handleEditClick(item)} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Eliminar">
                        <IconButton variant="outlined" >
                          <DeleteIcon style={{ color: "rgb(117,117,117)" }} onClick={() => handleDeleteClick(item)} />
                        </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      ) : (
        <Typography style={{ textAlign: "center", margin: "15px 0px" }}>No hay datos disponibles</Typography>
      )}

      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={(event, reason) => !(reason && reason === "backdropClick" && "escapeKeyDown") && setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteConfirmed}
        itemId={selectedItem?.idItemDePuntuacion}
      />

      <ProcedimientoModal
        isOpen={isProcedimientoModalOpen}
        onClose={() => setIsProcedimientoModalOpen(false)}
        selectedItem={selectedItem}
      />

      <EditItemModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        item={selectedItem}
        page={page}
        tiposItemsDePuntuacion={tipoItemPuntuacionQuery}
      />
    </TableContainer>
  );
};

export default PuntuacionTable;
