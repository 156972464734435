import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useMediaQuery, useTheme } from "@mui/material";
import { EntidadOtorganteContext } from "../../Page/EntidadOtorgantePage";
import {
  PostEntidadOtorgante,
  searchEntidadesOtorgantes,
} from "store/entidadesOtorgantes/entidadesOtorgantes.slice";
import EntidadOtorganteFrom from "../Common/EntidadOtorganteFrom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export default function NuevaEntidadOtorgante({ loadOption = null }) {
  const [open, setOpen] = React.useState(false);
  const { page, search } = React.useContext(EntidadOtorganteContext);
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick" && "escapeKeyDown") return;
    setOpen(false);
  };

  const handleSubmit = (entidadOtorgante) => {
    dispatch(PostEntidadOtorgante({ data: entidadOtorgante }));
    setOpen(false);
    if (loadOption) {
      loadOption();
    } else {
      setTimeout(() => {
        dispatch(
          searchEntidadesOtorgantes({ page: page ?? 1, search: search ?? "" })
        );
      }, 400);
    }
  };

  return (
    <React.Fragment>
      {!loadOption && (
        <Button variant="contained" onClick={handleClickOpen}>
          Nuevo
        </Button>
      )}

      {loadOption && (
        <IconButton onClick={handleClickOpen}>
          <AddCircleOutlineIcon fontSize="small" />
        </IconButton>
      )}

      <Dialog
        fullWidth={fullScreen}
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-paper": {
            width: "50%", // O el valor que necesites
            maxWidth: "none", // Elimina el límite máximo de tamaño
          },
        }}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>Agregar Entidad</Box>
            <Box>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <EntidadOtorganteFrom onSubmit={handleSubmit} onClose={handleClose} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
