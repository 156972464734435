import * as React from "react";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";

export default function SnackBarUtils({ view, message, timer, closed }) {
  const [open, setOpen] = React.useState(view);
  //console.log({message});
  const handleClose = () => {
    setOpen(false);
    closed(false);
  };

  React.useEffect(() => {
    setOpen(view);
  }, [view]);

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={timer ? parseFloat(timer) : 6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {message?.includes("500:") || message?.includes("400:") ? (
          <Alert severity="error">
            {message ? message.split("500:")[1] : "Error en el servicio"}
          </Alert>
        ) : message?.includes("200:") ? (
          <Alert severity="success">
            {message ? message.split("200:")[1] : "Ejecucion exitosa"}
          </Alert>
        ) : message?.includes("202:") ? (
          <Alert severity="info">
            {message ? message.split("202:")[1] : "Ejecucion exitosa"}
          </Alert>
        ) : message?.includes("204:") ? (
          <Alert severity="error">
            {message ? message.split("204:")[1] : "Sin resultados"}
          </Alert>
        ) : (
          <Alert severity="info">
            {message ? message : "Ejecucion exitosa"}
          </Alert>
        )}
      </Snackbar>
    </div>
  );
}
