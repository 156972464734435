import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";

//import { myPageTitulos } from "../TitulosPage/TituloPage";
//import { getTitulos } from "store/titulos/titulos.slice";
import {
  //getNivelesEnsenanza,
  selectNivelesEnsenanza,
  selectError,
  selectIsLoading,
} from "store/nivelesEnsenanza/nivelesEnsenanza.slice";
import {
  CircularProgress,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import {
  selectCategorias,
  selectIsError,
} from "store/categorias/cateogiras.slice";
import { vsTitulo } from "./YupTitulo";
import { Formik } from "formik";
import { selectErrorGradoDeTitulo, selectGradoDeTituloAll } from "store/gradoDeTitulo/gradoDeTitulo.slice";

export default function TituloForm({ data, onSubmit, onClose }) {

  //console.log('data', data)

  const [isEdit, setIsEdit] = useState(true);
  const sNivelesEnsenanza = useSelector(selectNivelesEnsenanza);
  const sNivelEnsenanzaIsError = useSelector(selectError);
  const sNivelEnsenanzaIsLoading = useSelector(selectIsLoading);
  const catalogoGradoDeTitulo = useSelector(selectGradoDeTituloAll);
  const gradoDeTituloIsError = useSelector(selectErrorGradoDeTitulo);
  const categoriasSource = useSelector(selectCategorias);
  const categoriaIsError = useSelector(selectIsError);

  const nivelesEnsenanzaSource =
    sNivelesEnsenanza?.records?.map((option) => ({
      id: option.idNivelEnsenanza,
      label: option.nivelEnsenanza,
    })) || [];

  useEffect(() => {
    setIsEdit(false);
    if (data?.idTitulo) {
      setIsEdit(true);
    }
  }, [data]);

  const handleSubmit = (data) => {
    const dataSend = data.idNivelEnsenanza.map((option) => ({
      idTitulo: data.idTitulo ? data.idTitulo : undefined,
      codigo: data.codigo,
      descripcion: data.descripcion,
      descAbrev: data.descAbrev,
      idNivelEnsenanza: option.id,
      idCategoria: data.idCategoria,
      idGradoDeTitulo: data.idGradoDeTitulo || 0,
    }));
    onSubmit(dataSend);
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <Formik
      initialValues={{
        idTitulo: data?.idTitulo || "",
        codigo: data?.codigo || "",
        descripcion: data?.descripcion || "",
        descAbrev: data?.descAbrev || "",
        idNivelEnsenanza: isEdit ? data?.idNivelEnsenanza || [] : [],
        idCategoria: data?.idCategoria || "",
        idGradoDeTitulo: data?.idGradoDeTitulo || "",
      }}
      validationSchema={vsTitulo}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        handleChange,
        touched,
        setFieldValue,
        submitForm,
        dirty,
      }) => (
        <Box component="form" sx={{ mt: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={Boolean(errors.idCategoria) && touched.idCategoria}
              >
                <InputLabel id="categoria">Categoría</InputLabel>
                <Select
                  labelId="categoria"
                  id="idCategoria"
                  label="Categoría"
                  name="idCategoria"
                  value={values?.idCategoria}
                  onChange={(e, value) => {
                    setFieldValue(
                      "idCategoria",
                      e?.target?.value ? e.target.value : null
                    );
                    e?.target?.value !== 1 && setFieldValue(
                      "idGradoDeTitulo", ""
                    )
                  }}
                  onSelect={handleChange}
                  error={Boolean(errors.idCategoria) && touched.idCategoria}
                >
                  {categoriasSource?.records?.map((option) => (
                    <MenuItem
                      key={option.idCategoria}
                      value={option.idCategoria}
                    >
                      {option.descripcion}
                    </MenuItem>
                  ))}
                </Select>
                {Boolean(errors.idCategoria) && touched.idCategoria && (
                  <FormHelperText
                    style={{
                      color: "#d32f2f",
                    }}
                  >
                    Este campo es obligatorio
                  </FormHelperText>
                )}
                {categoriaIsError && (
                  <FormHelperText
                    style={{
                      color: "#d32f2f",
                    }}
                  >
                    Ocurrió un problema al recuperar este dato
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={Boolean(errors.idGradoDeTitulo) && touched.idGradoDeTitulo}
              >
                <InputLabel id="gradoDeTituloLabel">Grado de Título</InputLabel>
                <Select
                  disabled={(values?.idCategoria == 1 ? false : true)}
                  labelId="gradoDeTituloLabel"
                  id="idGradoDeTitulo"
                  label="Grado de Título"
                  name="idGradoDeTitulo"
                  value={values?.idGradoDeTitulo}
                  onChange={(e, value) => {
                    setFieldValue(
                      "idGradoDeTitulo",
                      e?.target?.value ? e.target.value : null
                    );
                  }}
                  onSelect={handleChange}
                  error={Boolean(errors.idGradoDeTitulo) && touched.idGradoDeTitulo}
                >
                  {catalogoGradoDeTitulo?.records?.map((option) => (
                    <MenuItem
                      key={option.idGradoDeTitulo}
                      value={option.idGradoDeTitulo}
                    >
                      {option.descripcion}
                    </MenuItem>
                  ))}
                </Select>
                {Boolean(errors.idGradoDeTitulo) && touched.idGradoDeTitulo && values.idGradoDeTitulo == 1 && (
                  <FormHelperText
                    style={{
                      color: "#d32f2f",
                    }}
                  >
                    Este campo es obligatorio
                  </FormHelperText>
                )}
                {gradoDeTituloIsError && (
                  <FormHelperText
                    style={{
                      color: "#d32f2f",
                    }}
                  >
                    Ocurrió un problema al recuperar este dato
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  id="codigo"
                  label="Código"
                  name="codigo"
                  autoFocus
                  autoComplete="off"
                  onChange={handleChange}
                  value={values.codigo}
                  error={Boolean(errors.codigo && touched.codigo)}
                  helperText={
                    Boolean(errors.codigo) && touched.codigo && errors.codigo
                  }
                  inputProps={{ maxLength: 10 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  id="descAbrev"
                  label="Descripción abreviada"
                  name="descAbrev"
                  autoFocus
                  autoComplete="off"
                  onChange={handleChange}
                  value={values.descAbrev}
                  error={Boolean(errors.descAbrev && touched.descAbrev)}
                  helperText={
                    Boolean(errors.descAbrev) &&
                    touched.descAbrev &&
                    errors.descAbrev
                  }
                  inputProps={{ maxLength: 10 }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  id="descripcion"
                  label="Descripción"
                  name="descripcion"
                  variant="outlined"
                  autoFocus
                  autoComplete="off"
                  onChange={handleChange}
                  value={values.descripcion}
                  error={Boolean(errors.descripcion && touched.descripcion)}
                  inputProps={{ maxLength: 250 }}
                  helperText={
                    Boolean(errors.descripcion) &&
                    touched.descripcion &&
                    errors.descripcion
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                onChange={(e, value) => {
                  setFieldValue("idNivelEnsenanza", value);
                }}
                style={{ marginTop: "-7px" }}
                disableCloseOnSelect
                options={nivelesEnsenanzaSource}
                renderOption={(props, option, { selected }) => {
                  const { key, ...optionProps } = props;
                  return (
                    <li key={key} {...optionProps}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  );
                }}
                value={
                  nivelesEnsenanzaSource.filter((item) =>
                    values?.idNivelEnsenanza?.some(
                      (itemB) => itemB.id === item.id
                    )
                  ) || []
                }
                name="idNivelEnsenanza"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Nivel Enseñanza"
                    margin="normal"
                    variant="outlined"
                    autoComplete="off"
                    style={{ marginTop: "0.5rem" }}
                    error={errors.idNivelEnsenanza && touched.idNivelEnsenanza}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {sNivelEnsenanzaIsLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    helperText={
                      errors.idNivelEnsenanza &&
                      touched.idNivelEnsenanza &&
                      errors.idNivelEnsenanza
                    }
                  />
                )}
              />
              {sNivelEnsenanzaIsError && (
                <FormHelperText
                  style={{
                    color: "#d32f2f",
                  }}
                >
                  Ocurrió un problema al recuperar este dato
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <Stack
            spacing={2}
            direction="row"
            sx={{ display: "flex", justifyContent: "flex-end", mt: 3, mb: 2 }}
          >
            <Button
              variant="outlined"
              color="error"
              onClick={() => onClose()}
              spacing={2}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={() => submitForm()}
              disabled={!dirty}
            >
              Guardar
            </Button>
          </Stack>
        </Box>
      )}
    </Formik>
  );
}
