import { useMutation, useQueryClient } from "react-query";
import { updateFormacionDocente } from "store/formacionDocente/formacionDocente.api";

export const usePutFormacionDocenteQuery = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (formacionDocente) => {
      const { data } = await updateFormacionDocente({
        id: formacionDocente.idFormacionDocente,
        data: formacionDocente,
      });
      return data;
    },
    {
      onSuccess: () => {        
        queryClient.refetchQueries(["FormacionDocenteQuery"]);
      },
    }
  );
};
