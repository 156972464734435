import React from "react";
import { useDispatch } from "react-redux";
import { IconButton, Box, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { PutEntidadOtorgante, searchEntidadesOtorgantes } from "store/entidadesOtorgantes/entidadesOtorgantes.slice";
import { EntidadOtorganteContext } from "../../Page/EntidadOtorgantePage";
import EntidadOtorganteFrom from "../Common/EntidadOtorganteFrom";

export default function EditarEntidadOtorgante({ entidadOtorgante }) {
  const [open, setOpen] = React.useState(false);
  const {page, search} = React.useContext(EntidadOtorganteContext);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick" && "escapeKeyDown") return;
    setOpen(false);
  };

  const handleSubmit = (entidadOtorgante) => {    
    dispatch(PutEntidadOtorgante({id: entidadOtorgante.idEntidad, data: entidadOtorgante }));
    setOpen(false);
    setTimeout(() => {
        dispatch(searchEntidadesOtorgantes({ page: page ?? 1, search: search ?? "" })) ;
    }, 400);
  };

  return (
    <React.Fragment>
      <Tooltip title="Modificar">
        <span>
          <IconButton variant="outlined" onClick={handleClickOpen}>
            <EditIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>Editar Entidad</Box>
            <Box>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <EntidadOtorganteFrom
            onSubmit={handleSubmit}
            onClose={handleClose}
            data={entidadOtorgante}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
