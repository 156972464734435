import { Box, Pagination, Stack } from "@mui/material";
import Loader from "components/Loader/Loader";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCertificados,
  selectCertificados,
  selectLoading,
} from "store/certificado/certificado.slice";
import { CertificadosPersonaTable } from "./CertificadosPersonaTable";
import { PersonaContext } from "../Forms/Common/PersonaProvider";
import React from "react";

export const CertificadosPersonaPage = () => {
  const isLoading = useSelector(selectLoading);
  const dispatch = useDispatch();
  const { persona } = useContext(PersonaContext);
  const [page, setPage] = React.useState(1);
  const certificados = useSelector(selectCertificados);
  const handleChange = (event, values) => {
    setPage({ ...page, page: values });
  };

  useEffect(() => {
    dispatch(fetchCertificados(persona.idPersona));
  }, [dispatch, persona.idPersona]);

  return (
    <Box
      sx={{
        marginTop: 6,
      }}
    >
      {isLoading && <Loader />}
      <Box
        sx={{
          marginBottom: 2,
          marginLeft: 2,
          paddingTop: 2,
        }}
      ></Box>
      <CertificadosPersonaTable certificados={certificados} />
      <Stack spacing={2} style={{ margin: "20px 0px 30px 0px" }}>
        <Pagination
          count={certificados?.pages}
          page={page?.page}
          onChange={handleChange}
          color="primary"
        />
      </Stack>
    </Box>
  );
};
