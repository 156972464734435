import { configureStore } from "@reduxjs/toolkit";
import authReducer from "store/auth/auth.slice";
import proyectosReducer from "store/proyectos/proyectos.slice";
import actividadesReducer from "store/actividades/actividades.slice";
import institucionesReducer from "store/instituciones/instituciones.slice";
import estadosProyectoReducer from "store/estadosProyecto/estadosProyecto.slice";
import modalidadesProyectoReducer from "store/modalidadesProyecto/modalidadesProyecto.slice";
import tiposProyectosReducer from "store/tiposProyecto/tiposProyectos.slice";
import areasListReducer from "store/areasList/areasList.slice";
import subareasReducer from "store/subareas/subareas.slice";
import materiasReducer from "store/materias/materias.slice";
import titulosReducer from "store/titulos/titulos.slice";
import titulosxPersonaSlice from "./titulosxPersona/titulosxPersona.slice";
import inscripcionesxPersonaSlice from "./inscripcionesxPersona/inscripcionesxPersona.slice";
import competenciasReducer from "store/competencias/competencia.slice";
import NivelesEnsenanzaSlicereducer from "store/nivelesEnsenanza/nivelesEnsenanza.slice";
import CategoriasSlice from "store/categorias/cateogiras.slice";
import variablesReducer from "store/variables/variables.slice";
import variablesParamReducer from "store/variablesParam/variablesParam.slice";
import estadoVarGlobalesReducer from "store/estadoVarGlobales/estadoVarGlobales.slice";
import personaReducer from "./persona/persona.slice";
import EntidadesOtorgantesReducer from "./entidadesOtorgantes/entidadesOtorgantes.slice";
import PeriodoReducer from "./periodos/periodos.slice";
import TiposDePeriodoReducer from "./tiposDePeriodo/tiposDePeriodo.slice";
import ZonaSlice from "./zona/zona.slice";
import localidadesReducer from "./localidades/localidades.slice";
import puntuacionReducer from "./PuntuacionPersona/PuntuacionPersona.slice"; 
import localidadReducer from "store/Localidad/Localidad.slice";
import inscripcionGeneralReducer from "./inscripcionGeneral/inscripcionGeneral.slice";
import certificadosReducer from "./certificado/certificado.slice";
import gradoDeTituloReducer from "./gradoDeTitulo/gradoDeTitulo.slice"

export function configureAppStore() {
  const store = configureStore({
    reducer: {
      auth: authReducer,
      proyectos: proyectosReducer,
      actividades: actividadesReducer,
      instituciones: institucionesReducer,
      estadosProyecto: estadosProyectoReducer,
      modalidadesProyecto: modalidadesProyectoReducer,
      tiposProyecto: tiposProyectosReducer,
      areas: areasListReducer,
      subareas: subareasReducer,
      materias: materiasReducer,
      titulos: titulosReducer,
      titulosxPersona: titulosxPersonaSlice,
      inscripcionesxPersona: inscripcionesxPersonaSlice,
      competencias: competenciasReducer,
      nivelesEnsenanza: NivelesEnsenanzaSlicereducer,
      categorias: CategoriasSlice,
      variables: variablesReducer,
      variablesParam: variablesParamReducer,
      estadoVarGlobales: estadoVarGlobalesReducer,
      persona: personaReducer,
      entidadesOtorgantes: EntidadesOtorgantesReducer,
      periodo: PeriodoReducer,
      tiposDePeriodo: TiposDePeriodoReducer,
      zona: ZonaSlice,
      localidades: localidadesReducer,
      puntuacion: puntuacionReducer,  
      localidad: localidadReducer,
      inscripcionGeneral: inscripcionGeneralReducer,
      certificados: certificadosReducer,
      gradoDeTitulo: gradoDeTituloReducer,
    },
  });

  return store;
}
