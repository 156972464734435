import React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import { Box, Button, CircularProgress, InputAdornment, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import { ErrorMessage, Formik } from "formik";

import { inputPxA } from "../PuntajexPersonaStyles";
import "../PuntajexPersonaCss.css";


export default function PuntajexPersonaFormConsulta({
  persona,
  setCuil,
  isEnable,
  isFetching,
  setBandera,
}) {

  const dispatch = useDispatch();

  //Logica para enviar formulario al backend
  const handleSubmit = () => {                  // Siempre inicia en Página 1.
    //dispatch(getInscxPersona({ pBusqueda: undefined, page: 1, pId_Persona: pageAndSearch?.pId_Persona }));    
    setBandera(true);
  };

  //Campos del formulario
  const initialValue = {
    idPersona: 0,
    cuit: "",
  };

  //Validaciones del formulario.
  const validationSchema = Yup.object().shape({
    cuit: Yup.string()
      .matches(/^\d{11}$/, 'El CUIT debe contener 11 dígitos númericos.')
  });

  return (
    <Formik
      initialValues={initialValue}
      /* Aqui se obtienen los datos que se ingresó en el formulario */
      onSubmit={handleSubmit}
      /* Configuracion de Formik para agregar las validaciones */
      validationSchema={validationSchema}
    >
      {(formikProps) => (
        <Box
          component="form"
          style={{ borderRadius: "5px", marginLeft: "20px" }}
          sx={{ mt: 1 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={8} md={5}>

              <FormControl fullWidth>
                <TextField
                  type="text"
                  variant="outlined"
                  style={inputPxA}
                  label={`CUIL`}
                  placeholder="CUIL"
                  id="cuit"
                  name="cuit"
                  onChange={setCuil(formikProps.getFieldProps("cuit").value)}
                  {...formikProps.getFieldProps("cuit")}
                  InputProps={{
                    endAdornment: (
                      isFetching ? <InputAdornment position="end"><CircularProgress size={20} /></InputAdornment> : null
                    ),
                  }}
                ></TextField>
              </FormControl>
              <ErrorMessage name="cuit">
                {(mensaje) => <div style={{ color: "red" }}>{mensaje}</div>}
              </ErrorMessage>

              <FormControl fullWidth>
                <TextField
                  type="text"
                  style={inputPxA}
                  variant="outlined"
                  label={`Apellido y Nombre`}
                  InputProps={{ readOnly: true }}
                  disabled={true}
                  value={
                    isEnable && persona ? `${persona.apellidos}, ${persona.nombres}` : ""
                  }
                  id="personaInfo"
                  name="personaInfo"
                ></TextField>
              </FormControl>
              <ErrorMessage name="personaInfo">
                {(mensaje) => <div style={{ color: "red" }}>{mensaje}</div>}
              </ErrorMessage>

              <Button
                onClick={() => {
                  persona && formikProps.submitForm();                 // Seteo si el cuit es válido.  
                }}
                style={{ margin: "20px 0px", float: "right" }}
                disabled={!isEnable}
                variant="contained"
              >
                Consultar
              </Button>

            </Grid>
          </Grid>
        </Box>
      )}
    </Formik>
  );
}

