import { useQuery } from "react-query";
import {  ListarTipoItemDePuntuacionApi } from "store/PuntuacionPersona/PuntuacionPersona.api";

export const useTipoItemPuntuacionQuery = () => {
  return useQuery(
    {
      queryKey: ["tiposItemsPuntuacionQuery"],
      queryFn: async () => {
        const  data = await ListarTipoItemDePuntuacionApi();
        return data
          ?.sort((a, b) => {
            if (a.descripcion < b.descripcion) return -1;
            if (a.descripcion > b.descripcion) return 1;
            return 0;
          })
          .map((item) => ({
            ...item,
            descripcion: `${item?.descripcion}`,
            //descripcion: `${item?.idTipoItemsDePuntuacion} - ${item?.descripcion}`,
          }));
      },
    },
    {
      refetchOnWindowFocus: false, // Desactiva el re-fetch al enfocar la ventana
      refetchOnMount: false, // Desactiva el re-fetch al montar el componente
      refetchOnHover: false,
      refetchInterval: false,
      staleTime: 1000 * 60 * 60,
    }
  );  
};
