import { useQuery } from "react-query";
import {
  fetchFormacionDocente,
  fetchFormacionDocenteById,
} from "store/formacionDocente/formacionDocente.api";

export const useFormacionDocenteQuery = ({ page, idPersona }) => {
  const formacionDocenteQuery = useQuery(
    {
      queryKey: ["FormacionDocenteQuery", { page, idPersona }],
      queryFn: async () => {
        const  data = await fetchFormacionDocente(page, idPersona);
        return data;
      },
      enabled: !!page && !!idPersona, // Condición para habilitar la consulta
    },
    {
      refetchOnWindowFocus: false, // Desactiva el re-fetch al enfocar la ventana
      staleTime: 1000 * 60 * 60, // Establece el tiempo de caducidad de los datos
    }
  );

  return formacionDocenteQuery;
};

export const useFormacionDocenteByIdQuery = ({ id }) => {
  const formacionDocenteQuery = useQuery(
    {
      queryKey: ["FormacionDocenteByIdQuery", { id }],
      queryFn: async () => {
        const data = await fetchFormacionDocenteById(id);
        return data;
      },
      enabled: !!id, // Condición para habilitar la consulta
    },
    {
      refetchOnWindowFocus: false, // Desactiva el re-fetch al enfocar la ventana
      staleTime: 1000 * 60 * 60,
    }
  );

  return formacionDocenteQuery;
};
