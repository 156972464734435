import React, { useContext, useEffect, useState } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  styled,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import SchoolIcon from "@mui/icons-material/School";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { PersonaContext } from "../Common/PersonaProvider";
import { useTheme } from "@emotion/react";
import { useDispatch } from "react-redux";
import {
  clearEntitiesData,
  getTitulosxPersona,
} from "store/titulosxPersona/titulosxPersona.slice";

// Crear un componente estilizado usando styled
const StyledBottomNavigation = styled(BottomNavigation)({
  width: "100%", // Asegura que el BottomNavigation ocupe el ancho completo del contenedor
  display: "flex",
  justifyContent: "center", // Centra horizontalmente el grupo de íconos
  padding: 0, // Elimina el relleno para alinear los botones horizontalmente
  backgroundColor: "transparent", // Fondo transparente para el BottomNavigation
  marginTop: 15,
  marginBottom: 20,
});

// Estilizar BottomNavigationAction para manejar el texto en diferentes tamaños de pantalla y espaciado
const StyledBottomNavigationAction = styled(BottomNavigationAction)(
  ({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      height: 60, // Altura del botón en resoluciones pequeñas
      minWidth: 50, // Ancho mínimo del botón en resoluciones pequeñas
      padding: "0 5px", // Ajustar el padding para tamaño más pequeño
      margin: "0 10px", // Reducir el margen horizontal entre los botones
      "& .MuiBottomNavigationAction-label": {
        display: "none", // Oculta el texto del botón en pantallas pequeñas
      },
      borderRadius: "4px", // Redondear las esquinas del botón
    },
    [theme.breakpoints.down("sm")]: {
      height: 60, // Altura del botón en resoluciones pequeñas
      minWidth: 50, // Ancho mínimo del botón en resoluciones pequeñas
      padding: "0 5px", // Ajustar el padding para tamaño más pequeño
      margin: "0 0px", // Reducir el margen horizontal entre los botones
      "& .MuiBottomNavigationAction-label": {
        display: "none", // Oculta el texto del botón en pantallas pequeñas
      },
      borderRadius: "0px", // Redondear las esquinas del botón
    },
    height: 80, // Altura del botón en resoluciones normales
    minWidth: 70, // Ancho mínimo del botón en resoluciones normales
    margin: "0 10px", // Añadir margen horizontal entre los botones
    borderRadius: "4px", // Redondear las esquinas del botón
    border: "2px solid", // Borde
    backgroundColor: "#1976d2", // Fondo del botón
    borderColor: "#1976d2",
    color: "#ffffff", // Color del texto e ícono (blanco) para contraste
    "&:hover": {
      backgroundColor: "#1565c0", // Color de fondo en hover (azul más oscuro)
      borderColor: "#1565c0", // Cambiar el color del borde en hover (azul más oscuro)
    },
    "&:active": {
      backgroundColor: "#1565c0", // Color de fondo cuando se activa el botón (azul más oscuro)
      borderColor: "#1565c0", // Cambiar el color del borde cuando se activa (azul más oscuro)
    },
    "&.Mui-selected": {
      backgroundColor: "#5c749c", // Azul cuando está seleccionado
      borderColor: "#5c749c", // Borde azul
      color: "#ffffff", // Color del ícono en el botón azul cuando está seleccionado
    },
    // Estilo específico para los íconos
    "& .MuiBottomNavigationAction-icon": {
      fontSize: "2rem", // Ajusta el tamaño del ícono si es necesario
      color: "#ffffff", // Color del ícono (blanco) por defecto
    },
    "&.Mui-selected .MuiBottomNavigationAction-icon": {
      color: "#ffffff", // Color del ícono cuando el botón está seleccionado
    },
    "&.Mui-disabled": {
      "& .MuiBottomNavigationAction-icon": {
        color: "#9e9e9e", // Color gris para el ícono del botón deshabilitado
      },
      backgroundColor: "#e0e0e0", // Fondo gris claro para el botón deshabilitado
      borderColor: "#5c749c", // Borde gris claro para el botón deshabilitado
      color: "#9e9e9e", // Color del texto (si hay) en el botón deshabilitado
    },
  })
);

export const MenuForm = () => {
  const navigate = useNavigate(); // Inicializa useNavigate
  const [value, setValue] = useState(0);
  const { persona } = useContext(PersonaContext);
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!persona?.idPersona) {
      navigate("/legajo/persona/");
    }
  }, [persona, navigate]);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    // Redirige basado en el valor seleccionado
    switch (newValue) {
      case 1:
        dispatch(clearEntitiesData());
        dispatch(getTitulosxPersona(persona.idPersona));
        navigate("/legajo/persona/titulos");
        break;
      case 2:
        navigate("/legajo/persona/puntaje");
        break;
      case 3:
        navigate("/legajo/persona/inscripciones");
        break;
      case 4:
        navigate("/legajo/persona/certificados");
        break;
      case 5:
        navigate("/legajo/persona/");
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Box
        style={{
          textAlign: "center",
        }}
        sx={{
          marginTop: "20px",
          marginBottom: "20px",
          [theme.breakpoints.up("md")]: {
            mt: 0,
            mb: 0,
          },
        }}
      >
        <Link
          to="/legajo/persona/detalle"
          style={{
            color: "#1a4870",
            cursor: "pointer",
            textTransform: "uppercase",
            textDecoration: "none",
            fontSize: "calc(1rem + 1vw)", // Tamaño de fuente adaptable
            fontWeight: "bold",
          }}
          onClick={handleChange}
        >
          {persona.apellidoyNombre}
        </Link>
      </Box>
      <StyledBottomNavigation value={value} onChange={handleChange} showLabels>
        <StyledBottomNavigationAction
          label="Titulo"
          icon={<SchoolIcon />}
          value={1}
        />
        <StyledBottomNavigationAction
          label="Puntaje"
          icon={<TrendingUpIcon />}
          value={2}
        />
        <StyledBottomNavigationAction
          label="Inscripciones"
          icon={<AppRegistrationIcon />}
          value={3}
        />
        <StyledBottomNavigationAction
          label="Certificados"
          icon={<CardMembershipIcon />}
          value={4}
        />
        <StyledBottomNavigationAction
          label="Salir"
          icon={<ExitToAppIcon />}
          value={5}
        />
      </StyledBottomNavigation>
    </>
  );
};
