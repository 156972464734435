import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import CustomModal from "components/customModal/CustomModal";
import { PersonaContext } from "../Forms/Common/PersonaProvider";
import { SnackBarMessageResponse } from "utils/Response/SnackBarMessageResponse";
import FormacionDocenteForm from "./FormacionDocenteForm";
import { usePutFormacionDocenteQuery } from "Hooks/FormacionDocente/UsePutFormacionDocenteQuery";
import SnackBarUtils from "utils/MUI/SnackbarUtils";

export default function FormacionDocenteEditar({ data }) {
  const [open, setOpen] = useState(false);  
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState("");
  const putFormacionDocente = usePutFormacionDocenteQuery();
  const { persona } = useContext(PersonaContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick" && "escapeKeyDown") return;
    setOpen(false);
  };

  const handleSubmit = (data) => {
    putFormacionDocente.mutate(data, {
      onSuccess: () => {        
        setOpen(false);
        setMessage([
            {              
              message: "200: Formación docente actualizada correctamente",
            },
          ]);          
      },
      onError: (error) => {
        setMessage([
          {            
            message:`${error.response.status}: Se ha producido un error al modificar la formación docente`,              
          },
        ]);
      },
    });
  };

  const setMessage = (message) => {
    setOpenSnackbar(true);
    setMessageAlert(SnackBarMessageResponse(message));
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleClickOpen}>
        Editar
      </Button>
      <CustomModal open={open} setOpen={setOpen} title="Editar formación docente">
        <FormacionDocenteForm
          formacionDocente={data}
          onSubmit={handleSubmit}
          onClose={handleClose}
          persona={persona}
        />
      </CustomModal>
      <SnackBarUtils
        view={openSnackbar}
        message={messageAlert}
        closed={(value) => !value && setOpenSnackbar(value)}
      />
    </React.Fragment>
  );
}
