import * as Yup from "yup";

export const vFormacionDocente = Yup.object().shape({
  idTipoCertificacion: Yup.number().required("Este campo es obligatorio"),
  idDetalleTipoCertificacion: Yup.number().required("Este campo es obligatorio"),
  horas: Yup.number()    
    .positive("El Valor debe ser mayor que cero."),
  fechaDesde: Yup.date()
    .typeError("Ingrese una fecha válida"),   
  fechaHasta: Yup.date()
    .typeError("Ingrese una fecha válida")
    .min(Yup.ref("fechaDesde"), "La Fecha de Fin debe ser posterior o igual a la Fecha de Inicio")    
});