import { Box, FormControl, Grid, Typography } from "@mui/material";
import { useContext } from "react";
import { PersonaContext } from "../Common/PersonaProvider";
import { formatDDMMYY } from "concursos/utils/helpers/dateFormat";
import { useTheme } from "@emotion/react";

export const DetallePersona = () => {
  const { persona } = useContext(PersonaContext);
  const theme = useTheme();

  return (
    <Box
      sx={{
        marginTop: 6,
      }}
      style={{ padding: 20, backgroundColor: "#FFFFFF" }}
    >
      <Grid
        container
        spacing={0}
        columnSpacing={1}
        sx={{
          [theme.breakpoints.up("md")]: {
            pt: 4,
            px: 8,
          },
        }}
      >
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <Typography variant="subtitle1" color="text.secondary">
              Nombre y apellidos
            </Typography>
            <Typography variant="h6" gutterBottom>
              {persona?.nombres} {persona?.apellidos ?? " - "}
            </Typography>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <Typography variant="subtitle1" color="text.secondary">
              Sexo
            </Typography>
            <Typography variant="h6" gutterBottom>
              {persona?.sexos?.descripcion ?? " - "}
            </Typography>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <Typography variant="subtitle1" color="text.secondary">
              Estado civil
            </Typography>
            <Typography variant="h6" gutterBottom>
              {persona?.estadoCiviles?.estadoCivil ?? " - "}
            </Typography>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <Typography variant="subtitle1" color="text.secondary">
              Tipo de documento
            </Typography>
            <Typography variant="h6" gutterBottom>
              {persona?.tipo_documento?.descripcion ?? " - "}
            </Typography>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <Typography variant="subtitle1" color="text.secondary">
              Documento
            </Typography>
            <Typography variant="h6" gutterBottom>
              {persona?.documento ?? " - "}
            </Typography>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <Typography variant="subtitle1" color="text.secondary">
              CUIL
            </Typography>
            <Typography variant="h6" gutterBottom>
              {persona?.cuit ?? " - "}
            </Typography>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <Typography variant="subtitle1" color="text.secondary">
              Fecha de nacimiento
            </Typography>
            <Typography variant="h6" gutterBottom>
              {formatDDMMYY(persona?.fechaNacimiento) ?? " - "}
            </Typography>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <Typography variant="subtitle1" color="text.secondary">
              Lugar de nacimiento
            </Typography>
            <Typography variant="h6" gutterBottom>
              {persona?.lugarNacimiento ?? " - "}
            </Typography>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <Typography variant="subtitle1" color="text.secondary">
              Domicilio
            </Typography>
            <Typography variant="h6" gutterBottom>
              {persona?.domicilio ?? " - "}
            </Typography>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <Typography variant="subtitle1" color="text.secondary">
              Localidad
            </Typography>
            <Typography variant="h6" gutterBottom>
              {persona?.localidadResidencia?.localidad ?? " - "}
            </Typography>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};
