import { request } from "utils/helpers/request.helpers";

const entidadesOtorgantesUrl = `${process.env.REACT_APP_API_URL}/api/EntidadOtorgante`;

export function fetchAllEntidadesOtorgantes() {  
  const url = `${entidadesOtorgantesUrl}/ListarAllEntidades`;
  return request(url, { method: "GET" });
}

export function createEntidadOtorgante(data) {
  return request(`${entidadesOtorgantesUrl}`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function updateEntidadOtorgante({ id, data }) {
  return request(`${entidadesOtorgantesUrl}/${id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
}

export function fetchSearchEntidadesOtorgantes({ search, page }) {  
  const url = `${entidadesOtorgantesUrl}/Listar?search=${search}&page=${page}`;
  return request(url, { method: "GET" });
}