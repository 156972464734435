import { Box, Pagination, Stack } from "@mui/material";
import { TituloPersonaTable } from "./TituloPersonaTable";
import TituloPersonaNuevo from "./TituloPersonaNuevo";
import Loader from "components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsLoadingTitxPer,
  selectTitulosxPersona,
} from "store/titulosxPersona/titulosxPersona.slice";
import React, { useEffect } from "react";
import { getAllEntidadesOtorgantes } from "store/entidadesOtorgantes/entidadesOtorgantes.slice";
import { getAllTitulos } from "store/titulos/titulos.slice";

export const TituloPersonaPage = () => {
  const isLoading = useSelector(selectIsLoadingTitxPer);
  const dispatch = useDispatch();
  const titulos = useSelector(selectTitulosxPersona);
  const [page, setPage] = React.useState(1);

  useEffect(() => {
    dispatch(getAllEntidadesOtorgantes());
    dispatch(getAllTitulos());
  }, [dispatch]);

  const handleChange = (event, values) => {
    setPage({ ...page, page: values });
  };

  return (
    <Box
      sx={{
        marginTop: 6,
      }}
    >
      {isLoading && <Loader />}
      <Box
        sx={{
          marginBottom: 2,
          marginLeft: 2,
          paddingTop: 2,
        }}
      >
        <TituloPersonaNuevo />
      </Box>
      <TituloPersonaTable titulos={titulos} />
      <Stack spacing={2} style={{ margin: "20px 0px 30px 0px" }}>
        <Pagination
          count={titulos?.pages}
          page={page?.page}
          onChange={handleChange}
          color="primary"
        />
      </Stack>
    </Box>
  );
};
