import React from 'react';
import {
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Dialog
} from '@mui/material';

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, itemId }) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle> Eliminar ítem </DialogTitle>
      <DialogContent>
        <DialogContentText>
          ¿Estás seguro/a que deseas eliminar este ítem de puntuación?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}> Cancelar </Button>
        <Button onClick={() => onConfirm(itemId)} autoFocus>
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationModal;