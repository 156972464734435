import {
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import CustomTableContainer from "components/customTable/CustomTableContainer";
import MessageModal from "components/customTable/MessageModal";
import {
  tableBodyCell,
  tableBodyCenterCell,
  tableHeadCell,
} from "components/customTable/StylesTable";
import { formatDDMMYY } from "concursos/utils/helpers/dateFormat";
import React from "react";

export const CertificadosPersonaTable = ({ certificados }) => {
  const MAX_TEXT_LENGTH = 30;

  return (
    <>
      <CustomTableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            sx={{
              borderTop: "1px solid", // Agregar borde superior
            }}
          >
            <TableRow>
              <TableCell sx={{ ...tableHeadCell }}>Denominación</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Resolución</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Tipo de proyecto</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Destinado a</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Modalidad</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Tipo Certificado</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Horas Cátedra</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Con Evaluación</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Inicio Actividad</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Fin Actividad</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Domicilio</TableCell>
              <TableCell sx={{ ...tableHeadCell }}>Localidad</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {certificados?.data?.length > 0 ? (
              certificados?.data?.map((certificado) => (
                <TableRow key={certificado.certificado.idCertificacion}>
                  <TableCell sx={{ ...tableBodyCenterCell }}>
                    {certificado?.proyecto?.denominacion?.length >
                    MAX_TEXT_LENGTH
                      ? certificado?.proyecto?.denominacion.slice(
                          0,
                          MAX_TEXT_LENGTH
                        ) + "..."
                      : certificado?.proyecto?.denominacion}
                    {certificado?.proyecto?.denominacion?.length >
                      MAX_TEXT_LENGTH && (
                      <MessageModal
                        title="Denominación"
                        titleMessage="Denominación"
                        message={certificado?.proyecto?.denominacion}
                      />
                    )}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {certificado?.resolucion?.descripcion}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {certificado?.proyecto?.tipoProyecto?.denominacion}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCenterCell }}>
                    {certificado?.proyecto?.destinadoA?.length > MAX_TEXT_LENGTH
                      ? certificado?.proyecto?.destinadoA.slice(
                          0,
                          MAX_TEXT_LENGTH
                        ) + "..."
                      : certificado?.proyecto?.destinadoA}
                    {certificado?.proyecto?.destinadoA?.length >
                      MAX_TEXT_LENGTH && (
                      <MessageModal
                        title="Destinado a"
                        titleMessage="Destinado a"
                        message={certificado?.proyecto?.destinadoA}
                      />
                    )}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {certificado?.proyecto?.modalidadProyecto?.denominacion}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {certificado?.certificado?.tipoCertificado?.descripcion}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCenterCell }}>
                    {certificado.proyecto.horasCatedra}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCenterCell }}>
                    {certificado.proyecto.conEvaluacion === "S"
                      ? "Sí"
                      : certificado.registrado === "N"
                      ? "No"
                      : "Valor Inválido"}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {formatDDMMYY(certificado?.proyecto?.fechaInicio)}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {formatDDMMYY(certificado?.proyecto?.fechaFin)}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {certificado?.certificado?.actividad?.domicilio}
                  </TableCell>
                  <TableCell sx={{ ...tableBodyCell }}>
                    {
                      certificado?.certificado?.actividad?.localidadActividad
                        ?.localidad
                    }
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={15} sx={{ textAlign: "center" }}>
                  No se encontraron resultados
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CustomTableContainer>
    </>
  );
};
