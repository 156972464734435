import React, { createContext, useState } from "react";

// Componente Provider del contexto
export const FormacionDocenteProvider = ({ children }) => {
  const [page, setPage] = useState(1);
  const [formacionDocente, setFormacionDocente] = useState({});

  return (
    <FormacionDocenteContext.Provider
      value={{
        page,
        setPage,
        formacionDocente,
        setFormacionDocente
      }}
    >
      {children}
    </FormacionDocenteContext.Provider>
  );
};

// Creamos el contexto
export const FormacionDocenteContext = createContext();
