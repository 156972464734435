import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import SnackBarUtils from "utils/MUI/SnackbarUtils";
import { Stack, Pagination, Toolbar, Grid } from "@mui/material";

import Loader from "components/Loader/Loader";
import InscripcionesxPersonaTable from "../InscripcionesxPersonaTable/InscripcionesxPersonaTable";
import { SnackBarMessageResponse } from "utils/Response/SnackBarMessageResponse";
import InscripcionesxPersonaFormConsulta from "../InscripcionesxPersonaPage/InscripcionesxPersonaFormConsulta";
import InscripcionesxPersonaNuevo from "../Modal/InscripcionesxPersonaNuevo/InscripcionesxPersonaNuevo";
import { useQuery } from "react-query";
import { fetchPersonaByCuil } from "store/persona/persona.api";
import { toast } from "react-toastify";
import { clearInscxPersona, getInscxPersona, selectInscxPersona, selectIsLoadingInscxPer, selectResponseInscxPer } from "store/inscripcionesxPersona/inscripcionesxPersona.slice";
import { getNivelesEnsenanza } from "store/nivelesEnsenanza/nivelesEnsenanza.slice";
import { getSubareasPerComp } from "store/subareas/subareas.slice";
import { clearZonaxDepto } from "store/zona/zona.slice";
import GenericSearch from "concursos/utils/search/genericSearch";

export const InscxPersonaContext = React.createContext(1);

const InscripcionesxPersonaPage = () => {

  const dispatch = useDispatch();
  let [page, setPage] = React.useState(1);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState("");
  const [persona, setPersona] = useState(null);
  const [cuil, setCuil] = useState("");
  const [currentSearch, setcurrentSearch] = React.useState(undefined);
  const [bandera, setBandera] = useState(false)

  const GetInscripcionesPersona = useSelector(selectInscxPersona);
  const isLoading = useSelector(selectIsLoadingInscxPer);
  const response = useSelector(selectResponseInscxPer);

  const isEnable = useMemo(() => cuil && /^\d{11}$/.test(cuil), [cuil]);

  const { isLoading: isFetching } = useQuery(["getPersonaByCuil", cuil], () => fetchPersonaByCuil(cuil), {
    enabled: !!isEnable,
    onSuccess: (data) => {
      setPersona(data[0]);
    },
    onError: (error) => {
      toast.error("La Persona no Existe o el cuil ingresado es incorrecto");
      setPersona(null);
    },
  });

  useEffect(() => {
    dispatch(getNivelesEnsenanza());
  }, [dispatch]);

  useEffect(() => {
    persona && dispatch(getSubareasPerComp(persona?.idPersona));
  }, [dispatch, persona]);

  useEffect(() => {
    if (response) {
      setMessageAlert(SnackBarMessageResponse(response));
      setOpenSnackbar(true);
    }
  }, [response]);

  useEffect(() => {
    isEnable === true && dispatch(getInscxPersona({ pBusqueda: currentSearch, page: page, pId_Persona: persona?.idPersona }));
  }, [page, currentSearch, dispatch]);

  useEffect(() => {
    dispatch(clearInscxPersona());
    dispatch(clearZonaxDepto());
    setcurrentSearch(undefined);
    setBandera(false);
  }, [cuil, isFetching]);

  const muestroTabla = () => {
    if (!(GetInscripcionesPersona?.data === null || GetInscripcionesPersona?.data?.records?.length >= 0)) {
      return "";          // Concición de inicio.
    } else if (GetInscripcionesPersona?.data?.records?.length >= 0) {
      return (
        <InscripcionesxPersonaTable
          datosInscPersona={GetInscripcionesPersona?.data?.records}
          persona={persona}
        />
      );
    } else if (GetInscripcionesPersona?.data === null) {
      return (
        <p
          style={{
            textAlign: "center",
            marginBottom: "30px",
            color: "red",
            fontSize: "20px",
          }}
        >
          { currentSearch ? "Sin resultados para esta búsqueda." : "Esta persona no tiene Inscripciones cargadas." }
        </p>
      )
    } else {
      return "";
    }
  };

  const handleChange = (event, values) => {
    setPage(values);
  };

  const handleSearch = (e) => {
    if (e.code !== "Enter") return;
    setPage(1);
    setcurrentSearch(e.target.value);
  };

  return (
    <InscxPersonaContext.Provider value={{ pBusqueda: currentSearch, page: page, pId_Persona: persona?.idPersona }}>
      <React.Fragment>
        {isLoading && <Loader />}
        <h1 style={{ marginLeft: "12px" }}> Inscripciones por Persona </h1>
        <InscripcionesxPersonaFormConsulta
          persona={persona}
          setCuil={setCuil}
          isEnable={isEnable}
          isFetching={isFetching}
          setPage={setPage}
          setBandera={setBandera}
          setcurrentSearch={setcurrentSearch}
        />
        <div style={{ marginBottom: "30px" }} />
        {isEnable && bandera &&
          <>
            <p
              style={{
                textAlign: "center",
                background: "rgb(25,118,210,0.6)",
                borderRadius: "5px",
                padding: "10px",
                fontWeight: "bold",
              }}
            >
              {`INFORMACIÓN DE ${persona?.apellidos}, ${persona?.nombres}`}
            </p>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={4}>
                <Toolbar disableGutters>
                  <InscripcionesxPersonaNuevo persona={persona} />
                </Toolbar>
              </Grid>
              <Grid item xs={12} sm={8}>
                <GenericSearch
                  style={{ marginBottom: 0 }}
                  handleSearch={handleSearch}
                />
              </Grid>
            </Grid>
          </>
        }
        {isEnable && muestroTabla()}
        {isEnable && (GetInscripcionesPersona?.data?.records?.length >= 0) &&
          <Stack spacing={2} style={{ margin: "20px 0px 30px 0px" }}>
            <Pagination
              count={GetInscripcionesPersona?.data?.pages}
              page={page}
              onChange={handleChange}
              color="primary"
            />
          </Stack>
        }
      </React.Fragment >
      <SnackBarUtils
        view={openSnackbar}
        message={messageAlert}
        closed={(value) => !value && setOpenSnackbar(value)}
      />
    </InscxPersonaContext.Provider>
  );
};

export default InscripcionesxPersonaPage;
