import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  createEntidadOtorgante,
  fetchAllEntidadesOtorgantes,
  fetchSearchEntidadesOtorgantes,
  updateEntidadOtorgante,
} from "./entidadesOtorgantes.api";

const initialState = {
  entities: [],
  loading: false,
  creating: false,
  editing: false,
  deleting: false,
  response: null,
  error: null,
};

const errorMessage = {
  message: [
    {
      status: 500,
      description: "Se ha producido un error inesperado",
    },
  ],
};

export const getAllEntidadesOtorgantes = createAsyncThunk(
  "entidadesOtorgantes/getAllEntidadesOtorgantes",
  async () => {
    const r = await fetchAllEntidadesOtorgantes();
    //console.log('r get entidadesOtorgantes en slice', r)
    return r;
  }
);

export const searchEntidadesOtorgantes = createAsyncThunk(
  "entidadesOtorgantes/searchEntidadesOtorgantes",
  async ({search, page}) => {
    const r = await fetchSearchEntidadesOtorgantes({search, page});    
    return r;
  }
);

export const PostEntidadOtorgante = createAsyncThunk(
  "entidadesOtorgantes/PostEntidadOtorgante",
  async ({ data }) => {
    const r = await createEntidadOtorgante(data);
    return r;
  }
);

export const PutEntidadOtorgante = createAsyncThunk(
  "entidadesOtorgantes/PutEntidadOtorgante",
  async ({id, data }) => {
    const r = await updateEntidadOtorgante({id, data});
    return r;
  }
);

export const EntidadesOtorgantesSlice = createSlice({
  name: "entidadesOtorgantes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //#region getAllEntidadesOtorgantes
      .addCase(getAllEntidadesOtorgantes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllEntidadesOtorgantes.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload?.data;        
      })
      .addCase(getAllEntidadesOtorgantes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })
      //#endregion
      //#region getSearchEntidadesOtorgantes
      .addCase(searchEntidadesOtorgantes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(searchEntidadesOtorgantes.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload?.data;        
      })
      .addCase(searchEntidadesOtorgantes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })
      //#endregion
      //#region PostEntidadOtorgante
      .addCase(PostEntidadOtorgante.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.response = null;
      })
      .addCase(PostEntidadOtorgante.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action.payload;
      })
      .addCase(PostEntidadOtorgante.rejected, (state, action) => {
        state.loading = false;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      })
      //#endregion
      //#region PutEntidadOtorgante
      .addCase(PutEntidadOtorgante.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.response = null;
      })
      .addCase(PutEntidadOtorgante.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action.payload;
      })
      .addCase(PutEntidadOtorgante.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.response = action.error?.stack
          ? errorMessage
          : action.error.message;
      });
      //#endregion
  },
});

export const selectEntidadesOtorgantesAll = (state) => state.entidadesOtorgantes.entities;
export const selectEntidadesOtorgantesSearch = (state) => state.entidadesOtorgantes.entities;
export const selectIsLoading = (state) => state.entidadesOtorgantes.loading;
export const selectIsCreating = (state) => state.entidadesOtorgantes.creating;
export const selectIsEditing = (state) => state.entidadesOtorgantes.editing;
export const selectIsDeleting = (state) => state.entidadesOtorgantes.deleting;
export const selectError = (state) => state.entidadesOtorgantes.error;
export const selectResponse = (state) => state.entidadesOtorgantes.response;

export default EntidadesOtorgantesSlice.reducer;
