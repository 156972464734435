import * as Yup from "yup";

export const vsTitulo = Yup.object({
  idCategoria: Yup.string().required("Este campo es obligatorio").nullable(),
  idGradoDeTitulo: Yup.string()
    .nullable()
    .test(
      "required-if-idCategoria-is-1",
      "Este campo es obligatorio",
      function (value) {
        const { idCategoria } = this.parent;
        return idCategoria !== "1" || !!value;
      }
    ),
  descripcion: Yup.string().required("Este campo es obligatorio").nullable(),
  codigo: Yup.string().required("Este campo es obligatorio").nullable(),
  descAbrev: Yup.string().required("Este campo es obligatorio").nullable(),
  idNivelEnsenanza: Yup.array()
    .min(1, "Debe seleccionar al menos una opción")
    .required("Este campo es obligatorio"),
});
